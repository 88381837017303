import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
  IconButton,
  TablePagination,
  Grid,
  TextField
} from '@mui/material';
import AddPayroll from '../PayrollForm/addPayroll';
import SearchBarWithCheckbox from '../HelperComponents/SearchBarWithCheckbox';
import {
  deletePayroll,
  getClientWisePayrolls
} from 'src/services/payrollService';
import { formatClientId } from 'src/utils/helperFunctions';
import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ViewPayroll from '../viewPayroll/ViewPayroll';
import { NotificationManager } from 'react-notifications';
import ConfirmationDialogue from '../Dialog/confirmationDialogue';
import { BarLoader } from 'react-spinners';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import moment from 'moment';

interface Column {
  id:
    | 'clientID'
    | 'state'
    | 'legalBusinessName'
    | 'doingBusinessAs'
    | 'monthYear'
    | 'taxPayble'
    | 'totalSales'
    | 'totalGasoline'
    | 'totalExempt'
    | 'totalHighTax'
    | 'totalLowTax'
    | 'Status'
    | 'Actions';
  label: string;
  minWidth?: number;
  align?: 'right';
  //   format?: (value: number) => string;
}
const columnsForSearchbar: Column[] = [
  { id: 'clientID', label: 'Client ID', minWidth: 300 },
  { id: 'state', label: 'State', minWidth: 150 },
  { id: 'legalBusinessName', label: 'Legal Business Name', minWidth: 200 },
  { id: 'doingBusinessAs', label: 'Doing Business As', minWidth: 150 },
  { id: 'monthYear', label: 'Month/Year', minWidth: 150 }
];

const columns = [
  { id: 'clientID', label: 'Client ID'},
  { id: 'state', label: 'State' },
  { id: 'legalBusinessName', label: 'Legal Business Name', minWidth: 150 },
  { id: 'doingBusinessAs', label: 'Doing Business As', minWidth: 130 },
  { id: 'monthYear', label: 'Month/Year'},
  { id: 'numberOfEmployees', label: 'Number of Employees' },
  { id: 'totalGrossWages', label: 'Gross Wages' },
  { id: 'totalSocSec', label: 'Soc. Sec.' },
  { id: 'totalMedicare', label: 'Medicare'},
  { id: 'totalFederalWH', label: 'Federal WH'},
  { id: 'tax941', label: '941 Tax'},
  { id: 'totalFuta', label: 'FUTA Tax'},
  { id: 'totalStateWH', label: 'State WH'},
  { id: 'totalSuta', label: 'SUTA Tax'},
  { id: 'Actions', label: 'Actions'}
];

const Payroll = () => {
  const [addPayrollModal, setAddPayrollModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [payrolls, setPayrolls] = useState([]);
  const [selectedPayroll, setSelectedPayroll] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [isLoading, setIsLoading] = useState(false);
  const [isViewPayrollModal, setIsViewPayrolModal] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [unFilteredPayrolls, setunFilteredPayrolls] = useState([]);
  const [rows, setRows] = useState([]);
  const [selectedStartMonth, setSelectedStartMonth] = useState(null);
  const [selectedEndMonth, setSelectedEndMonth] = useState(null);
  const [selectedColumns, setSelectedColumns] = useState<string[]>(['Legal Business Name','Doing Business As']);
  const [searchValue, setSearchValue] = useState<string>('');
  const openModal = () => {
    setAddPayrollModal(true);
  };

  const styles = {
    cell:{
      border: '1px solid #E3E1D9',
      borderCollapse:'collapse',
      padding:'6px'
    }
  }
  const closeModal = () => {
    setAddPayrollModal(false);
    setIsViewPayrolModal(false);
    getPayrolls();
  };

const getPayrolls = async (startDate?: string, endDate?: string) => {
    setLoader(true);

    try {
      if(selectedStartMonth && selectedEndMonth){
        let startDate = moment(selectedStartMonth).format('YYYY-MM-DD');
      let endDate = moment(selectedEndMonth).format('YYYY-MM-DD');
      }

        const payrollsResponse = await getClientWisePayrolls(startDate, endDate);

        if (payrollsResponse && payrollsResponse.data) {
            const sortedResponse = sortByYearAndMonth(payrollsResponse.data);
            setPayrolls(sortedResponse);
            setunFilteredPayrolls(payrollsResponse.data);
            // handleFilter(selectedColumns, searchValue);
        }
    } catch (error) {
        console.error("Error fetching payroll data:", error);
    } finally {
        setLoader(false);
    }
};

  const sortByYearAndMonth = (data:any)=>{
    return data.sort((a, b) => {
      // Compare years first
      const yearDifference = b.year - a.year;
      if (yearDifference !== 0) {
        return yearDifference;
      }
      // If years are the same, compare months
      return b.month - a.month;
    });
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getPayrolls();
      } catch (error) {
        console.error('Error fetching employees:', error);
      }
    };
    fetchData();
  }, []);
  function handlePayrollView(index: number): void {
    try {
      setIsLoading(true);
      setSelectedPayroll(payrolls[index].employeePayrolls);
      setIsViewPayrolModal(true);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching employees:', error);
    }
  }

  const closeConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
  };
  const handleDelete = (index: number) => {
    console.log('Payroll delete clicked', index);
    setSelectedIndex(index);
    setOpenConfirmationDialog(true);
  };
  const deleteSelectedPayroll = async () => {
    try {
      setIsLoading(true);
      const deletePayload = {
        clientId: payrolls[selectedIndex].clientId,
        month: payrolls[selectedIndex].month,
        year: payrolls[selectedIndex].year
      };

      console.log({ deletePayload });
      await deletePayroll(deletePayload);
      setOpenConfirmationDialog(false);
      NotificationManager.success('Successfully deleted Payroll');
      getPayrolls();
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching employees:', error);
    }
  };

  //sort and search

  const sortTableDataByColumnsAndSearch = (
    data: any,
    selectedColumns: string[],
    searchValue: string
  ) => {

    if (searchValue.trim() === '' || !selectedColumns.length) {
      // Return all data if searchValue is empty
      return data;
    }

   
    const filteredData = data.filter((item: any) => {
      let isMatch = false;
      searchValue = searchValue.toLocaleLowerCase();
      selectedColumns.forEach((column) => {
        switch (column) {
          case 'Client ID':
            if (item.client.clientId?.toLowerCase().includes(searchValue)) {
              isMatch = true;
            }
            break;
          case 'State':
            if (item.client.state?.toLowerCase().includes(searchValue)) {
              isMatch = true;
            }
            break;
          case 'Legal Business Name':
            if (item.client.legalBusinessName?.toLowerCase().includes(searchValue)) {
              isMatch = true;
            }
            break;
          case 'Doing Business As':
            if (item.client.doingBusinessAs?.toLowerCase().includes(searchValue)) {
              isMatch = true;
            }
            break;
          case 'Federal Tax ID':
            if (item.client.federalTaxId?.toLowerCase().includes(searchValue)) {
              isMatch = true;
            }
            break;
          case 'Contact Person':
            if (item.client.contactPerson?.toLowerCase().includes(searchValue)) {
              isMatch = true;
            }
            break;
          case 'Contact Email':
            if (item.client.contactEmail?.toLowerCase().includes(searchValue)) {
              isMatch = true;
            }
            break;
          case 'Contact Number':
            if (item.client.contactNumber?.toLowerCase().includes(searchValue)) {
              isMatch = true;
            }
            break;
          case 'Accountant':
            if (item.client.accountantName?.toLowerCase().includes(searchValue)) {
              isMatch = true;
            }
            break;
          // Add cases for other columns as needed
          default:
            break;
        }
      });

      return isMatch;
    });

    return filteredData;
  };


  const handleFilter = (selectedColumns: string[], searchValue: string) => {
    if (searchValue === '') {
      setPayrolls(unFilteredPayrolls);
      return;
    }
    const filteredData = sortTableDataByColumnsAndSearch(
      unFilteredPayrolls,
      selectedColumns,
      searchValue
    );
    setPayrolls(filteredData);
  };

  const handleExportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    // Define columns
    worksheet.columns = [
      { header: 'Client Id', key: 'id', width: 6 },
      { header: 'State', key: 'state', width: 30 },
      { header: 'Legal Business Name', key: 'legalBusinessName', width: 30 },
      { header: 'Doing Business As', key: 'doingBusinessAs', width: 30 },
      { header: 'Month/Year', key: 'month', width: 12 },
      { header: 'Number of Employees', key: 'numberOfEmployees', width: 15 },
      { header: 'Gross Wages', key: 'grossWages', width: 15},
      { header: 'Soc. Sec.', key: 'socSec', width: 15 },
      { header: 'Medicare', key: 'medicare', width: 15 },
      { header: 'Federal Wh', key: 'federalWh', width: 15 },
      { header: '941 Tax', key: 'tax941', width: 15 },
      { header: 'FUTA Tax', key: 'futa', width: 15 },
      { header: 'State Wh', key: 'stateWh', width: 15 },
      { header: 'SUTA Tax', key: 'suta', width: 15 },
    ];


    worksheet.getRow(1).font = { bold: true };
    worksheet.getRow(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFF00' }, // Yellow background
    };
    // Add rows
    payrolls.forEach(row => {
      worksheet.addRow({
        id: row.client ? formatClientId(+row.client?.id) : 'N/A',
        state:   row.client?.state,
        legalBusinessName: row.client?.legalBusinessName,
        doingBusinessAs: row.client?.doingBusinessAs,
        month: `${row.month.slice(0, 3)}-${row.year}`,
        numberOfEmployees: row.employeePayrolls && +row.employeePayrolls.length,
        grossWages: row.employeePayrolls &&
        +row.employeePayrolls
          .reduce(
            (total, payroll) =>
              total + parseFloat(payroll.grossWages),
            0
          )
          .toFixed(2),
        socSec: row.employeePayrolls &&
        +row.employeePayrolls
          .reduce(
            (total, payroll) =>
              total + parseFloat(payroll.socSec),
            0
          )
          .toFixed(2),
        medicare: row.employeePayrolls &&
        +row.employeePayrolls
          .reduce(
            (total, payroll) =>
              total + parseFloat(payroll.medicare),
            0
          )
          .toFixed(2),
        federalWh: row.employeePayrolls &&
        +row.employeePayrolls
          .reduce(
            (total, payroll) =>
              total + parseFloat(payroll.federalWH),
            0
          )
          .toFixed(2),
        tax941: row.employeePayrolls &&
        +(
          (row.employeePayrolls.reduce(
            (total, payroll) =>
              total + parseFloat(payroll.socSec),
            0
          ) +
            row.employeePayrolls.reduce(
              (total, payroll) =>
                total + parseFloat(payroll.medicare),
              0
            )) *
            2 +
          row.employeePayrolls.reduce(
            (total, payroll) =>
              total + parseFloat(payroll.federalWH),
            0
          )
        ).toFixed(2),
        futa: row.employeePayrolls &&
        +row.employeePayrolls
          .reduce(
            (total, payroll) =>
              total + parseFloat(payroll.futaTax),
            0
          )
          .toFixed(2),
        stateWh: row.employeePayrolls &&
        +row.employeePayrolls
          .reduce(
            (total, payroll) =>
              total + parseFloat(payroll.stateWH),
            0
          )
          .toFixed(2),
        suta: row.employeePayrolls &&
        +row.employeePayrolls
          .reduce(
            (total, payroll) =>
              total + parseFloat(payroll.sutaTax),
            0
          )
          .toFixed(2),
      });
    });

    // Save to file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, 'export.xlsx');
  };
  const handleClearFilter = async () => {
    try {
      // setPayrolls(unFilteredPayrolls);
      getPayrolls();
      setSelectedStartMonth(null);
      setSelectedEndMonth(null);
      setSearchValue('');
      setSelectedColumns(['Legal Business Name', 'Doing Business As']);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (selectedStartMonth === null && selectedEndMonth === null && searchValue === '') {
      setPayrolls(unFilteredPayrolls);
    }
  }, [selectedStartMonth, selectedEndMonth, searchValue]);

  useEffect(() => {
    handleFilter(selectedColumns, searchValue);
  }, [unFilteredPayrolls]);
  useEffect(() => {
    const fetchData = async () => {
      if(!selectedStartMonth || !selectedEndMonth) return;
      let startDate = moment(selectedStartMonth).format('YYYY-MM-DD');
      let endDate = moment(selectedEndMonth).format('YYYY-MM-DD');
      
      try {
        await getPayrolls(startDate,endDate);
      } catch (error) {
        console.error('Error fetching data:', error);
        NotificationManager.error(
          `Error fetching data:, ${error}`,
          'Error',
          3000
        );

      }
    };

    fetchData(); // Call the function to fetch data when component mounts
  }, [selectedStartMonth,selectedEndMonth,]);

  return (
    <>
      <Box sx={{ mt: 1, mx: '1vw' }}>
        {loader && (
          <div className="loader-wrapper" style={{ textAlign: 'center' }}>
            <BarLoader color={'#1976D2'} loading={loader} width={'96vw'} />
          </div>
        )}
        <Box display={'flex'} justifyContent={'center'} width={'100vw'}>
          <Typography variant="h4" color={'#1976D2'}>
            PAYROLL
          </Typography>
        </Box>        
        <Grid container mt={2}>
        <Grid item xs={12} md={4}>
          <Box
            display="flex"
            justifyContent="flex-start"
            // width={{ xs: '56', sm: '42vw', md: '42vw' }}
            // marginTop={'15px'}
            marginRight={'5px'}
          >
             <SearchBarWithCheckbox
              columns={columnsForSearchbar}
              onSearch={handleFilter}
              value={searchValue}
              scolumns={selectedColumns}
              updateSearchValue={setSearchValue}
              updateSelectedColumns={setSelectedColumns}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4} sx={{ ml: { md: 10 }, mt: { md: 0, xs: 2 } }}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={4} mt={0}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Box sx={{ width: '100%' }}>
                  <DatePicker
                    label="Start Month"
                    views={['year', 'month']}
                    openTo="year"
                    value={selectedStartMonth}
                    inputFormat="MMM yyyy"
                    onChange={setSelectedStartMonth}
                    renderInput={(props) => (
                      <TextField {...props} style={{ width: '100%' }} />
                    )}
                    InputProps={{
                      size: 'small',
                      name: 'startMonth'
                    }}
                  />
                </Box>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6} md={4} mt={0}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Box sx={{ width: '100%' }}>
                  <DatePicker
                    label="End Month"
                    views={['year', 'month']}
                    openTo="year"
                    value={selectedEndMonth}
                    inputFormat="MMM yyyy"
                    onChange={setSelectedEndMonth}
                    renderInput={(props) => (
                      <TextField {...props} style={{ width: '100%' }} />
                    )}
                    InputProps={{
                      size: 'small',
                      name: 'endMonth'
                    }}
                  />
                </Box>
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2} md={1} sx={{ mt: { md: 0, xs: 2 } }}>
          <Grid container display="flex" justifyContent="space-between">
            <Grid item xs={3.5}  md={4}>
            <Button
              variant="contained"
              color="warning"
              onClick={handleClearFilter}
              sx={{ scale: { xs: '0.8', md: '1' } }}
            >
              Clear
            </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8} md={2} sx={{ mt: { md: 0, xs: 2 }, ml: { xs: 3 } }}>
          <Grid container display="flex" justifyContent="space-between">
            <Grid item xs={4}  md={5}>
            <Button
              variant="contained"
              color="success"
              onClick={handleExportToExcel}
              sx={{ scale: { xs: '0.8', md: '1' } }}
            >
              Export
              <FileDownloadIcon />
            </Button>
            </Grid>
            <Grid item xs={8}  md={7}>
              <Button
                variant="contained"
                sx={{
                  padding: '10px',
                  scale: '0.9',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  '&:hover': {
                    transform: 'scale(0.9)',
                    transition: 'all 0.3s ease'
                  },
                  ml:{xs:5}
                  // width: '100px', // Fixed width for the button // Aligns the button to the end
                }}
                onClick={openModal}
              >
                Add Payroll
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

        <Box mt={3}>
          <Paper sx={{ width: '96vw', overflow: 'hidden' }} elevation={3}>
            <TableContainer sx={{ width: '100%', overflow: 'auto' }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id}   
                      style={{
                        minWidth: column.minWidth,
                        fontWeight: '800',
                        color: '#1976D2',
                        border: '1px solid #E3E1D9',
                        borderCollapse:'collapse',
                        padding:5
                      }}>
                        <TableSortLabel>{column.label}</TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {payrolls
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        <TableCell sx={styles.cell}>{formatClientId(+row.clientId)}</TableCell>
                        <TableCell sx={styles.cell}>{row.client && row.client.state}</TableCell>
                        <TableCell sx={styles.cell}>
                          {row.client && row.client.legalBusinessName}
                        </TableCell>
                        <TableCell sx={styles.cell}>
                          {row.client && row.client.doingBusinessAs}
                        </TableCell>
                        <TableCell sx={styles.cell}>{`${row.month}-${row.year}`}</TableCell>
                        <TableCell sx={styles.cell}>
                          {row.employeePayrolls && row.employeePayrolls.length}
                        </TableCell>
                        <TableCell sx={styles.cell}>
                          {row.employeePayrolls &&
                            row.employeePayrolls
                              .reduce(
                                (total, payroll) =>
                                  total + parseFloat(payroll.grossWages),
                                0
                              )
                              .toFixed(2)}
                        </TableCell>
                        <TableCell sx={styles.cell}>
                          {row.employeePayrolls &&
                            row.employeePayrolls
                              .reduce(
                                (total, payroll) =>
                                  total + parseFloat(payroll.socSec),
                                0
                              )
                              .toFixed(2)}
                        </TableCell>
                        <TableCell sx={styles.cell}>
                          {row.employeePayrolls &&
                            row.employeePayrolls
                              .reduce(
                                (total, payroll) =>
                                  total + parseFloat(payroll.medicare),
                                0
                              )
                              .toFixed(2)}
                        </TableCell>
                        <TableCell sx={styles.cell}>
                          {row.employeePayrolls &&
                            row.employeePayrolls
                              .reduce(
                                (total, payroll) =>
                                  total + parseFloat(payroll.federalWH),
                                0
                              )
                              .toFixed(2)}
                        </TableCell>
                        <TableCell sx={styles.cell}>
                          {row.employeePayrolls &&
                            (
                              (row.employeePayrolls.reduce(
                                (total, payroll) =>
                                  total + parseFloat(payroll.socSec),
                                0
                              ) +
                                row.employeePayrolls.reduce(
                                  (total, payroll) =>
                                    total + parseFloat(payroll.medicare),
                                  0
                                )) *
                                2 +
                              row.employeePayrolls.reduce(
                                (total, payroll) =>
                                  total + parseFloat(payroll.federalWH),
                                0
                              )
                            ).toFixed(2)}
                        </TableCell>
                        <TableCell sx={styles.cell}>
                          {row.employeePayrolls &&
                            row.employeePayrolls
                              .reduce(
                                (total, payroll) =>
                                  total + parseFloat(payroll.futaTax),
                                0
                              )
                              .toFixed(2)}
                        </TableCell>
                        <TableCell sx={styles.cell}>
                          {row.employeePayrolls &&
                            row.employeePayrolls
                              .reduce(
                                (total, payroll) =>
                                  total + parseFloat(payroll.stateWH),
                                0
                              )
                              .toFixed(2)}
                        </TableCell>
                        <TableCell sx={styles.cell}>
                          {row.employeePayrolls &&
                            row.employeePayrolls
                              .reduce(
                                (total, payroll) =>
                                  total + parseFloat(payroll.sutaTax),
                                0
                              )
                              .toFixed(2)}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            // marginTop: '20px',
                            ...styles.cell
                          }}
                        >
                          <IconButton
                            // onClick={() => handleEdit(index)}
                            style={{ color: '#1976D2', cursor: 'pointer' }}
                          >
                            <VisibilityIcon
                              onClick={() => handlePayrollView(index)}
                              style={{ color: '#1976D2', cursor: 'pointer' }}
                            />
                          </IconButton>
                          <IconButton
                            // onClick={() => handleEdit(index)}
                            style={{ color: '#1976D2', cursor: 'pointer' }}
                          >
                            <DeleteIcon
                              onClick={() => handleDelete(index)}
                              style={{ color: '#1976D2', cursor: 'pointer' }}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[50, 100, 200]}
              count={payrolls.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
        <AddPayroll open={addPayrollModal} onClose={closeModal} />
        <ViewPayroll
          open={isViewPayrollModal}
          onClose={closeModal}
          payroll={selectedPayroll}
        />
        {
          <ConfirmationDialogue
            open={openConfirmationDialog}
            close={closeConfirmationDialog}
            confirmationMessage="Are you sure you want to delete this Payroll"
            onConfirm={deleteSelectedPayroll}
          />
        }
      </Box>
    </>
  );
};

export default Payroll;

const calculateSum = (array: any) => {};
function setRows(arg0: any) {
  throw new Error('Function not implemented.');
}
