import {
  Box,
  Modal,
  Typography,
  Button,
  Grid,
  Tab,
  Tabs,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import ViewField from './viewField';
import { Close as CloseIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { formatClientId } from 'src/utils/helperFunctions';
import moment from 'moment';

interface BasicTabContentProps {
  clientData: any; // Replace 'any' with the actual type of clientData
}

interface Column {
  id: 'ssnFein' | 'fullLegalName' | 'title' | 'type' | 'ownershipPercent';
  label: string;
  minWidth?: number;
  align?: 'right';
  //   format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: 'ssnFein', label: 'SSN/FEIN' },
  { id: 'fullLegalName', label: 'Full Legal Name' },
  { id: 'title', label: 'Title' },
  { id: 'type', label: 'Type' },
  { id: 'ownershipPercent', label: 'Ownership Percentage' }
];

const ViewClient = ({ open, onClose, client }) => {
  const [rows, setRows] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [tabName, setTabName] = useState('Basic');
  const navigate = useNavigate();

  const [clientData, setClientData]: any = useState({
    clientId: '',
    federalTaxId: '',
    businessStructure: '',
    taxClassification: '',
    legalBusinessName: '',
    doingBusinessAs: '',
    industry: '',
    sosNumber: '',
    businessFormationDate: null,
    otherIndustry: '',
    streetAddress1: '',
    streetAddress2: '',
    city: '',
    county: '',
    state: '',
    zipCode: '',
    mailingAddressSameAsLocation: false,
    mailingStreetAddress1: '',
    mailingStreetAddress2: '',
    mailingCity: '',
    mailingCounty: '',
    mailingState: '',
    mailingZipCode: '',
    registeredAgent: '',
    contactPerson: '',
    contactNumber: '',
    contactEmail: '',
    contactFax: '',
    property: false,
    salesTax: false,
    businessTax: false,
    franchiseTax: false,
    monthlyFees: '',
    monthlyFeesStartDate: null,
    payroll: false,
    inactive: false,
    inactiveDate: null,
    reasonForInactive: null,
    otherReason: '',
    accountantName: '',
    additionalNotes: '',
    salesStartDate: null
  });

  const [clientSalesTaxData, setClientSalesTaxData]: any = useState({
    salesTaxId: '',
    routingNumber: '',
    accountNumber: '',
    stateHighTax: '',
    stateLowTax: '',
    cityCountyTax: '',
    countyTax: '',
    cityTax: '',
    liquorTax: '',
    beerTax: '',
    cbdTax: '',
    specialTax: '',
    fees911: '',
    discountRate: '',
    contactName: '',
    contactNumber: '',
    contactEmail: '',
    salesStartDate: null,
    importantNotes: ''
  });

  const [businessTaxData, setBusinessTaxData]: any = useState({
    businessTaxId: '',
    locationId: '',
    businessType: '',
    classification: '',
    taxRate: '',
    gasolineTaxPerGallonFederal: '',
    gasolineTaxPerGallonState: '',
    dieselTaxPerGallonFederal: '',
    dieselTaxPerGallonState: '',
    avgGasolineTaxPerGallonFederal: '',
    avgGasolineTaxPerGallonState: '',
    tobaccoTaxPackOf20Federal: '',
    tobaccoTaxPackOf20State: '',
    tobaccoTaxOnOtpOfWholesaleCostFederal: '',
    tobaccoTaxOnOtpOfWholesaleCostState: '',
    tobaccoTaxPerLargeCigarFederal: '',
    tobaccoTaxPerLargeCigarState: '',
    tobaccoTaxOnPipePerPoundFederal: '',
    tobaccoTaxOnPipePerPoundState: '',
    tobaccoTaxOnRyoPerPoundFederal: '',
    tobaccoTaxOnRyoPerPoundState: '',
    beerTaxPerGallonFederal: '',
    beerTaxPerGallonState: ''
  });
  const [payrollData, setPayrollData]: any = useState({
    eftpsPin: '',
    withHoldingNumber: '',
    laborAccNumber: '',
    payrollSchedule: '',
    payrollDeliveryMethod: '',
    payrollProcessingDay: '',
    payrollStartDate: null,
    importantNotes: '',
    contactName: '',
    contactNumber: '',
    contactEmail: ''
  });
  const [clientOwnershipData, setClientOwnershipData]: any = useState({
    ssnFein: '',
    fullLegalName: '',
    title: '',
    type: '',
    ownershipPercent: ''
  });
  const tabs = [
    { label: 'Basic', condition: true },
    { label: 'Sales Tax', condition: !!clientData.salesTax },
    { label: 'Business Tax', condition: !!clientData.businessTax },
    { label: 'Payroll', condition: !!clientData.payroll },
    { label: 'Ownership', condition: true },
    { label: 'Notes', condition: true }
    // Add more tabs as needed
  ];
  const visibleTabs = tabs.filter((tab) => tab.condition);

  useEffect(() => {
    // If client object is provided, populate the form fields
    if (client) {
      console.log(client);
      setClientData(client);
      if (client.salesTaxes) {
        setClientSalesTaxData(client.salesTaxes);
      }
      if (client.businessTaxes) {
        setBusinessTaxData(client.businessTaxes);
      }
      if (client.payrolls) {
        setPayrollData(client.payrolls);
      }
      if (client.ownerships && client.ownerships.length) {
        setRows(client.ownerships);
      }
    }
  }, [client]);
  useEffect(() => {}, [rows]);

  const handleClose = () => {
    onClose();
    setClientData({
      clientId: '',
      federalTaxId: '',
      businessStructure: '',
      taxClassification: '',
      legalBusinessName: '',
      doingBusinessAs: '',
      industry: '',
      sosNumber: '',
      businessFormationDate: null,
      otherIndustry: '',
      streetAddress1: '',
      streetAddress2: '',
      city: '',
      county: '',
      state: '',
      zipCode: '',
      mailingAddressSameAsLocation: false,
      mailingStreetAddress1: '',
      mailingStreetAddress2: '',
      mailingCity: '',
      mailingCounty: '',
      mailingState: '',
      mailingZipCode: '',
      registeredAgent: '',
      contactPerson: '',
      contactNumber: '',
      contactEmail: '',
      contactFax: '',
      property: false,
      salesTax: false,
      businessTax: false,
      franchiseTax: false,
      monthlyFees: '',
      monthlyFeesStartDate: null,
      payroll: false,
      inactive: false,
      inactiveDate: null,
      reasonForInactive: null,
      otherReason: '',
      accountantName: '',
      additionalNotes: ''
    });
    setClientSalesTaxData({
      salesTaxId: '',
      routingNumber: '',
      accountNumber: '',
      stateHighTax: '',
      stateLowTax: '',
      cityCountyTax: '',
      countyTax: '',
      cityTax: '',
      liquorTax: '',
      beerTax: '',
      cbdTax: '',
      specialTax: '',
      fees911: '',
      discountRate: '',
      contactName: '',
      contactNumber: '',
      contactEmail: '',
      importantNotes: ''
    });
    setBusinessTaxData({
      businessTaxId: '',
      locationId: '',
      businessType: '',
      classification: '',
      taxRate: '',
      gasolineTaxPerGallonFederal: '',
      gasolineTaxPerGallonState: '',
      dieselTaxPerGallonFederal: '',
      dieselTaxPerGallonState: '',
      avgGasolineTaxPerGallonFederal: '',
      avgGasolineTaxPerGallonState: '',
      tobaccoTaxPackOf20Federal: '',
      tobaccoTaxPackOf20State: '',
      tobaccoTaxOnOtpOfWholesaleCostFederal: '',
      tobaccoTaxOnOtpOfWholesaleCostState: '',
      tobaccoTaxPerLargeCigarFederal: '',
      tobaccoTaxPerLargeCigarState: '',
      tobaccoTaxOnPipePerPoundFederal: '',
      tobaccoTaxOnPipePerPoundState: '',
      tobaccoTaxOnRyoPerPoundFederal: '',
      tobaccoTaxOnRyoPerPoundState: '',
      beerTaxPerGallonFederal: '',
      beerTaxPerGallonState: ''
    });
    setPayrollData({
      eftpsPin: '',
      withHoldingNumber: '',
      laborAccNumber: '',
      payrollSchedule: '',
      payrollDeliveryMethod: '',
      payrollProcessingDay: '',
      payrollStartDate: null,
      importantNotes: '',
      contactName: '',
      contactNumber: '',
      contactEmail: ''
    });
  };

  const handlePrint = () => {
    navigate('/print');
  };
  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    // Do something with the new tab label

    setTabValue(newValue);
    setTabName(visibleTabs[newValue].label);
  };

  return (
    <div>
      {/* <Button variant="contained" onClick={handleOpen}>
          Open Modal
        </Button> */}
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          // Other styles...
          backdropFilter: 'blur(8px)', // Adjust blur intensity as needed
          WebkitBackdropFilter: 'blur(8px)' // For Safari support
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%', // Adjust this percentage as needed
            height: '85%', // Adjust this percentage as needed
            bgcolor: 'background.paper',
            borderRadius: 4,
            boxShadow: 24,
            p: 4,
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              display: 'none' // Hide scrollbar for Chrome/Safari
            },
            msOverflowStyle: 'none', // Hide scrollbar for IE and Edge
            scrollbarWidth: 'none'
          }}
        >
          <CloseIcon
            sx={{
              position: 'sticky',
              top: -25,
              left: '100%',
              right: -10,
              cursor: 'pointer',
              zIndex: 2,
              color: 'red'
            }}
            onClick={() => handleClose()}
          />

          <div
            style={{
              backgroundColor: 'whitesmoke',
              boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
              borderRadius: '10px',
              padding: '8px',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              width: 'auto', // Adjust padding as needed
              display: 'flex',
              position: 'sticky',
              top: 0, // Stick to the top of the viewport
              zIndex: 1
            }}
          >
            <Tabs
              value={tabValue}
              onChange={handleChangeTab}
              centered
              TabIndicatorProps={{ style: { background: '#1976D2' } }}
              variant="scrollable"
              sx={{
                '@media (max-width: 600px)': {
                  '.MuiTab-root': {
                    flexGrow: 1,
                    minWidth: '20%'
                  }
                }
              }}
            >
              {visibleTabs.map((tab) => (
                <Tab key={tab.label} label={tab.label} />
              ))}
            </Tabs>
          </div>
          {/* Heading Box */}
          {/* <Box display={'flex'} justifyContent={'center'} mt={4}>
              <Typography variant="h4" color={'#323a45'}>
                View Client
              </Typography>
            </Box> */}

          {/* Content Box */}
          <Box display={'flex'} justifyContent={'around'} mt={4}>
            {tabName === 'Basic' && <BasicTabContent clientData={clientData} />}

            {tabName === 'Sales Tax' && (
              <Grid container spacing={{ xs: 2, sm: 1 }}>
                <ViewField label="State" value={clientData.state} />
                <ViewField label="County" value={clientData.county} />
                <ViewField label="City" value={clientData.city} />
                <ViewField
                  label="Sales Tax ID"
                  value={clientSalesTaxData.salesTaxId}
                />
                <ViewField
                  label="Routing #"
                  value={clientSalesTaxData.routingNumber}
                />
                <ViewField
                  label="Account #"
                  value={clientSalesTaxData.accountNumber}
                />
                {/* <Grid item container xs={12} sm={12} spacing={2}> */}
                <Grid item xs={12} sm={12}>
                  <Box p={1} mt={1}>
                    <Typography variant="h5" color={'#d27519'}>
                      Tax Information
                    </Typography>
                  </Box>
                </Grid>
                <ViewField
                  label="State High Tax"
                  value={
                    Number(clientSalesTaxData.stateHighTax).toFixed(2) + '%'
                  }
                />
                <ViewField
                  label="State Low Tax"
                  value={
                    Number(clientSalesTaxData.stateLowTax).toFixed(2) + '%'
                  }
                />
                <ViewField
                  label="City/County Taxes"
                  value={
                    Number(clientSalesTaxData.cityCountyTax).toFixed(2) + '%'
                  }
                />
                <ViewField
                  label="County Tax"
                  value={Number(clientSalesTaxData.countyTax).toFixed(2) + '%'}
                />
                <ViewField
                  label="City Tax"
                  value={Number(clientSalesTaxData.cityTax).toFixed(2) + '%'}
                />
                <ViewField
                  label="Liquor Tax"
                  value={Number(clientSalesTaxData.liquorTax).toFixed(2) + '%'}
                />
                <ViewField
                  label="Beer Tax"
                  value={Number(clientSalesTaxData.beerTax).toFixed(2) + '%'}
                />
                <ViewField
                  label="CBD Tax"
                  value={Number(clientSalesTaxData.cbdTax).toFixed(2) + '%'}
                />
                <ViewField
                  label="Special Tax"
                  value={Number(clientSalesTaxData.specialTax).toFixed(2) + '%'}
                />
                <ViewField
                  label="911 Fees"
                  value={'$' + Number(clientSalesTaxData.fees911).toFixed(2)}
                />
                <ViewField
                  label="Discount Rate"
                  value={
                    Number(clientSalesTaxData.discountRate).toFixed(2) + '%'
                  }
                />
                <ViewField label="" value="" />
                {/* </Grid> */}
                <Grid item xs={12} sm={12}>
                  <Grid item xs={12} sm={12}>
                    <Box p={1}>
                      <Typography variant="body2" color={'#1976D2'}>
                        Important Note
                        <br />
                        <Typography
                          component="span"
                          color={'black'}
                          whiteSpace={'pre-line'}
                        >
                          {clientSalesTaxData.importantNotes}
                        </Typography>
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box p={1} mt={1}>
                    <Typography variant="h5" color={'#d27519'}>
                      Contact Information
                    </Typography>
                  </Box>
                </Grid>
                <ViewField
                  label="Contact Name"
                  value={clientSalesTaxData.contactName}
                />
                <ViewField
                  label="Contact Number"
                  value={clientSalesTaxData.contactNumber}
                />
                <ViewField
                  label="Contact Email"
                  value={clientSalesTaxData.contactEmail}
                />
                <ViewField
                  label="Sales Start Date"
                  value={new Date(
                    clientSalesTaxData.salesStartDate
                  ).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                  })}
                />
              </Grid>
            )}

            {tabName === 'Business Tax' && (
              <Grid container spacing={{ xs: 1, sm: 1, md: 1 }}>
                <ViewField label="State" value={clientData.state} />
                <ViewField label="County" value={clientData.county} />
                <ViewField label="City" value={clientData.city} />
                <ViewField
                  label="Business Tax ID"
                  value={businessTaxData.businessTaxId}
                />
                <ViewField
                  label="Location ID"
                  value={businessTaxData.locationId}
                />
                <ViewField
                  label="Business Type"
                  value={businessTaxData.businessType}
                />
                <ViewField
                  label="Classification"
                  value={businessTaxData.classification}
                />
                <ViewField label="Tax Rate" value={businessTaxData.taxRate} />
                <Grid item xs={12} sm={12}>
                  <Box p={1} mb={1}></Box>
                </Grid>
                <TableContainer
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Table style={{ textAlign: 'center' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell
                          style={{ color: '#1976D2', fontWeight: 'bold' }}
                        >
                          Federal
                        </TableCell>
                        <TableCell
                          style={{ color: '#1976D2', fontWeight: 'bold' }}
                        >
                          State
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          style={{ color: '#d27519', fontWeight: 'semibold' }}
                        >
                          Gasoline Tax per Gallon
                        </TableCell>
                        <TableCell>
                          $ {businessTaxData.gasolineTaxPerGallonFederal}
                        </TableCell>
                        <TableCell>
                          $ {businessTaxData.gasolineTaxPerGallonState}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ color: '#d27519', fontWeight: 'semibold' }}
                        >
                          Diesel Tax per Gallon
                        </TableCell>
                        <TableCell>
                          $ {businessTaxData.dieselTaxPerGallonFederal}
                        </TableCell>
                        <TableCell>
                          $ {businessTaxData.dieselTaxPerGallonState}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ color: '#d27519', fontWeight: 'semibold' }}
                        >
                          Avg Gasoline Tax per Gallon
                        </TableCell>
                        <TableCell>
                          $ {businessTaxData.avgGasolineTaxPerGallonFederal}
                        </TableCell>
                        <TableCell>
                          $ {businessTaxData.avgGasolineTaxPerGallonState}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ color: '#d27519', fontWeight: 'semibold' }}
                        >
                          Tobacco Tax on Cigs -Pack of 20
                        </TableCell>
                        <TableCell>
                          $ {businessTaxData.tobaccoTaxPackOf20Federal}
                        </TableCell>
                        <TableCell>
                          $ {businessTaxData.tobaccoTaxPackOf20State}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ color: '#d27519', fontWeight: 'semibold' }}
                        >
                          Tobacco Tax on OTP - of wholesale cost
                        </TableCell>
                        <TableCell>
                          {businessTaxData.tobaccoTaxWholesaleCost | 0}%
                        </TableCell>
                        <TableCell>
                          {businessTaxData.tobaccoTaxWholesaleCost | 0}%
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ color: '#d27519', fontWeight: 'semibold' }}
                        >
                          Tobbaco Tax on Large Cigar - Per Cigar
                        </TableCell>
                        <TableCell>
                          $ {businessTaxData.tobaccoTaxPerLargeCigarFederal}
                        </TableCell>
                        <TableCell>
                          $ {businessTaxData.tobaccoTaxPerLargeCigarState}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ color: '#d27519', fontWeight: 'semibold' }}
                        >
                          Tobacco Tax on Pipe - Per pound
                        </TableCell>
                        <TableCell>
                          $ {businessTaxData.tobaccoTaxOnPipePerPoundFederal}
                        </TableCell>
                        <TableCell>
                          $ {businessTaxData.tobaccoTaxOnPipePerPoundState}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ color: '#d27519', fontWeight: 'semibold' }}
                        >
                          Tobacco Tax on RYO - Per pound
                        </TableCell>
                        <TableCell>
                          $ {businessTaxData.tobaccoTaxOnRyoPerPoundFederal}
                        </TableCell>
                        <TableCell>
                          $ {businessTaxData.tobaccoTaxOnRyoPerPoundState}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ color: '#d27519', fontWeight: 'semibold' }}
                        >
                          Beer Tax per Gallon
                        </TableCell>
                        <TableCell>
                          $ {businessTaxData.beerTaxPerGallonFederal}
                        </TableCell>
                        <TableCell>
                          $ {businessTaxData.beerTaxPerGallonState}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}

            {tabName === 'Payroll' && (
              <Grid container spacing={{ xs: 1, sm: 1 }}>
                <Grid item xs={12} sm={12}>
                  <Box p={1} mt={1}>
                    <Typography variant="h5" color={'#d27519'}>
                      Basic Information
                    </Typography>
                  </Box>
                </Grid>
                <ViewField label="State" value={clientData.state} />
                <ViewField label="County" value={clientData.county} />
                <ViewField label="City" value={clientData.city} />
                <ViewField label="EFTPS PIN" value={payrollData.eftpsPin} />
                <ViewField
                  label="Withholding #"
                  value={payrollData.withHoldingNumber}
                />
                <ViewField
                  label="Labor A/c #"
                  value={payrollData.laborAccNumber}
                />
                <Grid item xs={12} sm={12}>
                  <Box p={1} mt={4}>
                    <Typography variant="h5" color={'#d27519'}>
                      Payroll Information
                    </Typography>
                  </Box>
                </Grid>
                <ViewField
                  label="Payroll Schedule"
                  value={payrollData.payrollSchedule}
                />
                <ViewField
                  label="Payroll Delivery Method"
                  value={payrollData.payrollDeliveryMethod}
                />
                <ViewField
                  label="Payroll Processing Day"
                  value={payrollData.payrollProcessingDay}
                />
                <ViewField
                  label="Payroll Start Date"
                  value={new Date(
                    payrollData.payrollStartDate
                  ).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                  })}
                />
                <Grid item xs={12} sm={12}>
                  <Grid item xs={12} sm={12}>
                    <Box p={1}>
                      <Typography variant="body2" color={'#1976D2'}>
                        Important Note
                        <br />
                        <Typography
                          component="span"
                          color={'black'}
                          whiteSpace={'pre-line'}
                        >
                          {payrollData.importantNotes}
                        </Typography>
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box p={1} mt={4}>
                    <Typography variant="h5" color={'#d27519'}>
                      Contact Information
                    </Typography>
                  </Box>
                </Grid>
                <ViewField
                  label="Contact Name"
                  value={payrollData.contactName}
                />
                <ViewField
                  label="Contact Number"
                  value={payrollData.contactNumber}
                />
                <ViewField
                  label="Contact Email"
                  value={payrollData.contactEmail}
                />
              </Grid>
            )}

            {tabName === 'Ownership' && (
              <>
                <Grid container spacing={{ xs: 1, sm: 1 }}>
                  <Grid item xs={12} sm={12}>
                    <Box p={1} mt={1}>
                      <Typography variant="h5" color={'#d27519'}>
                        Ownerships
                      </Typography>
                    </Box>
                  </Grid>

                  {rows.length > 0 && (
                    <Box mt={3} sx={{ width: '100%' }}>
                      <Paper
                        sx={{ width: '100%', overflow: 'hidden' }}
                        elevation={3}
                      >
                        <TableContainer sx={{ maxHeight: 400 }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                {columns.map((column) => (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{
                                      minWidth: column.minWidth,
                                      fontWeight: '800',
                                      color: '#1976D2'
                                    }}
                                  >
                                    {column.label}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {rows.map((row, index) => {
                                return (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={index}
                                  >
                                    {columns.map((column) => {
                                      return (
                                        <TableCell
                                          key={column.id}
                                          align={column.align}
                                        >
                                          {row[column.id]}
                                        </TableCell>
                                      );
                                    })}
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    </Box>
                  )}
                </Grid>
              </>
            )}

            {tabName === 'Notes' && (
              <Grid container spacing={{ xs: 1, sm: 1 }}>
                <Grid item xs={12} sm={12}>
                  <Box p={1} mt={1}>
                    <Typography variant="h5" color={'#d27519'}>
                      Notes
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{ textAlign: 'start', whiteSpace: 'pre-line' }}
                >
                  {clientData.additionalNotes}
                </Grid>
              </Grid>
            )}
          </Box>
          {/* <Box mt={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={handlePrint} variant="contained" color="primary">
              Print <PrintIcon />
            </Button>
          </Box> */}
        </Box>
      </Modal>
    </div>
  );
};

export default ViewClient;

const BasicTabContent = ({ clientData }) => (
  <div>
    <Grid container spacing={{ xs: 1, sm: 1 }}>
      <Grid item xs={12} sm={12}>
        <Box p={1} mt={1}>
          <Typography variant="h5" color={'#d27519'}>
            Basic Information
          </Typography>
        </Box>
      </Grid>
      <ViewField label="Client ID" value={formatClientId(clientData.id)} />
      <ViewField label="Federal Tax ID" value={clientData.federalTaxId} />
      <ViewField
        label="Business Structure"
        value={clientData.businessStructure}
      />
      <ViewField
        label="Tax Classification"
        value={clientData.taxClassification}
      />
      <ViewField
        label="Legal Business Name"
        value={clientData.legalBusinessName}
      />
      <ViewField label="Doing Business As" value={clientData.doingBusinessAs} />
      <ViewField
        label="Industry"
        value={
          clientData.industry == 'Other-Specify'
            ? clientData.otherIndustry
            : clientData.industry
        }
      />
      <ViewField label="SOS #" value={clientData.sosNumber} />

      <ViewField
        label="Business Formation Date"
        value={formatDate(clientData.businessFormationDate)}
        // value={new Date(clientData.businessFormationDate).toLocaleDateString(
        //   'en-US',
        //   {
        //     year: 'numeric',
        //     month: '2-digit',
        //     day: '2-digit'
        //   }
        // )}
      />

      <Grid item xs={12} sm={12}>
        <Box p={1} mt={4}>
          <Typography variant="h5" color={'#d27519'}>
            Location Address
          </Typography>
        </Box>
      </Grid>
      <ViewField label="Street Address 1" value={clientData.streetAddress1} />
      <ViewField label="Street Address 2" value={clientData.streetAddress2} />
      <ViewField label="City" value={clientData.city} />
      <ViewField label="County" value={clientData.county} />
      <ViewField label="State" value={clientData.state} />
      <ViewField label="Zip Code" value={clientData.zipCode} />

      <Grid item xs={12} sm={12}>
        <Box p={1} mt={4}>
          <Typography variant="h5" color={'#d27519'}>
            Mailing Address
          </Typography>
        </Box>
      </Grid>
      <ViewField
        label="Street Address 1"
        value={clientData.mailingStreetAddress1}
      />
      <ViewField
        label="Street Address 2"
        value={clientData.mailingStreetAddress2}
      />
      <ViewField label="City" value={clientData.mailingCity} />
      <ViewField label="Country" value={clientData.mailingCounty} />
      <ViewField label="State" value={clientData.mailingState} />
      <ViewField label="Zip Code" value={clientData.mailingZipCode} />

      <Grid item xs={12} sm={12}>
        <Box p={1} mt={4}>
          <Typography variant="h5" color={'#d27519'}>
            Contact Information
          </Typography>
        </Box>
      </Grid>
      <ViewField label="Registered Agent" value={clientData.registeredAgent} />
      <ViewField label="Contact Person" value={clientData.contactPerson} />
      <ViewField label="Contact Number" value={clientData.contactNumber} />
      <ViewField label="Contact Email" value={clientData.contactEmail} />
      <ViewField label="Contact Fax" value={clientData.contactFax} />
      <ViewField label="Accountant Name" value={clientData.accountantName} />

      <Grid item xs={12} sm={12}>
        <Box p={1} mt={4}>
          <Typography variant="h5" color={'#d27519'}>
            Services
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={12}
        spacing={1}
        sx={{
          flexWrap: 'wrap', // Allow items to wrap on smaller screens
          '& > .MuiGrid-item': {
            // Apply styles to each Grid item
            xs: 12, // Each item takes full width on extra-small screens
            sm: 6, // Two items per row on small screens (adjust as needed)
            md: 4, // Three items per row on medium screens (adjust as needed)
            lg: 3 // Four items per row on large screens (adjust as needed)
          }
        }}
      >
        <ViewField
          label="Property"
          value={!!clientData.property ? 'Active' : 'Inactive'}
        />
        <ViewField
          label="Sales Tax"
          value={!!clientData.salesTax ? 'Active' : 'Inactive'}
        />
        <ViewField
          label="Business Tax"
          value={!!clientData.businessTax ? 'Active' : 'Inactive'}
        />
        <ViewField
          label="Franchise Tax"
          value={!!clientData.franchiseTax ? 'Active' : 'Inactive'}
        />
        <ViewField
          label="Payroll"
          value={!!clientData.payroll ? 'Active' : 'Inactive'}
        />
        <ViewField
          label="Annual Report"
          value={!!clientData.annualReport ? 'Active' : 'Inactive'}
        />
        <ViewField
          label="Bookkeeping"
          value={!!clientData.bookKeeping ? 'Active' : 'Inactive'}
        />
        <ViewField
          label="Tax Return Preparation"
          value={!!clientData.taxReturnPreparation ? 'Active' : 'Inactive'}
        />
        <ViewField
          label="TPP Return Preparation"
          value={!!clientData.tppReturnPreparation ? 'Active' : 'Inactive'}
        />
        <ViewField
          label="Status"
          value={!!clientData.inactive ? 'Inactive' : 'Active'}
        />
        <ViewField label="Monthly Fees" value={clientData.monthlyFees} />

        <ViewField
          label="Monthly Fees Start Date"
          value={formatDate(clientData.monthlyFeesStartDate)}
        />
        {!!clientData.franchiseTax && (
          <ViewField
            label="Franchise Tax Number"
            value={clientData.franchiseTaxNumber}
          />
        )}
        {!!clientData.annualReport && (
          <ViewField
            label="Annual Report Due Date"
            value={formatDate(clientData.annualReportDueDate)}
          />
        )}
        {!!clientData.taxReturnPreparation && (
          <ViewField
            label="Tax Return Due Date"
            value={formatDate(clientData.taxReturnDueDate)}
          />
        )}
        {!!clientData.tppReturnPreparation && (
          <ViewField
            label="TPP Return Due Date"
            value={formatDate(clientData.tppReturnDueDate)}
          />
        )}

        {!!clientData.inactive && (
          <ViewField
            label="Reason for Inactive"
            value={
              clientData.reasonForInactive == 'Other -Specify'
                ? clientData.otherReason
                : clientData.reasonForInactive
            }
          />
        )}
        {!!clientData.inactive && (
          <ViewField
            label="InActive Date"
            value={formatDate(clientData.inactiveDate)}
          />
        )}
      </Grid>
    </Grid>
  </div>
);

const formatDate = (dateString) => {
  const formattedDate = moment(new Date(dateString), 'MM-DD-YYYY').format(
    'MMM-DD-YYYY'
  );
  return formattedDate;
};
