import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  Box,
  Typography,
  TextField,
  Grid,
  AppBar,
  Tabs,
  Tab,
  InputLabel,
  InputAdornment,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextFieldProps
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import TextInput from './textInput';
import Dropdown from './dropdown';
import FormHeading from './formHeading';
import RadioYesNo from './radioYesNo';
import CheckBox from './checkBox';
import {
  reasonsForInactive,
  businessStructures,
  taxClassifications,
  states,
  businessTypes,
  classifications,
  payrollSchedules,
  payrollDeliveryMethods,
  payrollProcessingDays,
  ownershipTitles,
  industries
} from '../../constants/clients';
import {
  validateBasicData,
  validateBusinessTaxData,
  validateOwnershipData,
  validatePayrollData,
  validateSalesTaxData
} from 'src/validations/clientForm';
import {
  addAdditionalNotes,
  addBusinessTax,
  addOwnership,
  addPayroll,
  addSalesTax,
  registerClient
} from 'src/services/clientService';
import Swal from 'sweetalert2';
import { getAllEmployees } from 'src/services/employeeService';
import TextLabelSmall from './textLabelSmall';
import DoubleInput from './doubleInput';
import { NotificationManager } from 'react-notifications';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { BarLoader } from 'react-spinners';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MultiSelectCheckbox from './multiSelectCheckBox';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

interface Column {
  id:
    | 'ssnFein'
    | 'fullLegalName'
    | 'title'
    | 'type'
    | 'ownershipPercent'
    | 'actions';
  label: string;
  minWidth?: number;
  align?: 'right';
}

const columns: readonly Column[] = [
  { id: 'ssnFein', label: 'SSN/FEIN' },
  { id: 'fullLegalName', label: 'Full Legal Name' },
  { id: 'title', label: 'Title' },
  { id: 'type', label: 'Type' },
  { id: 'ownershipPercent', label: 'Ownership Percentage' },
  { id: 'actions', label: 'Actions' }
];

const ClientsForm = ({ open, onClose, client, action }) => {
  const [rows, setRows] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [newClient, setNewClient] = useState(client);
  const [tabValue, setTabValue] = useState(0);
  const [tabName, setTabName] = useState('Basic');
  // const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [ownershipAction, setOwnershipAction] = useState('add');
  const [editOwnershipIndex, setEditOwnershipIndex] = useState(-1);

  // State for storing field values
  const [clientData, setClientData]: any = useState({
    clientId: '',
    federalTaxId: '',
    businessStructure: '',
    taxClassification: '',
    legalBusinessName: '',
    doingBusinessAs: '',
    industry: '',
    otherIndustry: '',
    sosNumber: '',
    businessFormationDate: null,
    streetAddress1: '',
    streetAddress2: '',
    city: '',
    county: '',
    state: '',
    zipCode: '',
    mailingAddressSameAsLocation: false,
    mailingStreetAddress1: '',
    mailingStreetAddress2: '',
    mailingCity: '',
    mailingCounty: '',
    mailingState: '',
    mailingZipCode: '',
    registeredAgent: '',
    contactPerson: '',
    contactNumber: '',
    contactEmail: '',
    contactFax: '',
    property: false,
    salesTax: false,
    businessTax: false,
    franchiseTax: false,
    franchiseTaxNumber: '',
    payroll: false,
    inactive: false,
    inactiveDate: null,
    MonthlyFees: '',
    annualReport: false,
    bookKeeping: false,
    taxReturnPreparation: false,
    tppReturnPreparation: false,
    annualReportDueDate: null,
    taxReturnDueDate: null,
    tppReturnDueDate: null,
    monthlyFeesStartDate: null,
    reasonForInactive: null,
    otherReason: '',
    accountantName: '',
    additionalNotes: ''
  });

  const [clientSalesTaxData, setClientSalesTaxData]: any = useState({
    salesTaxId: '',
    routingNumber: '',
    accountNumber: '',
    stateHighTax: '',
    stateLowTax: '',
    cityCountyTax: '',
    countyTax: '',
    cityTax: '',
    liquorTax: '',
    beerTax: '',
    cbdTax: '',
    specialTax: '',
    fees911: '',
    discountRate: '',
    contactName: '',
    contactNumber: '',
    contactEmail: '',
    salesStartDate: null,
    importantNotes: ''
  });

  const [businessTaxData, setBusinessTaxData]: any = useState({
    businessTaxId: '',
    locationId: '',
    businessType: '',
    classification: '',
    taxRate: '',
    gasolineTaxPerGallonFederal: '0.184',
    gasolineTaxPerGallonState: '0.274',
    dieselTaxPerGallonFederal: '0.244',
    dieselTaxPerGallonState: '0.284',
    avgGasolineTaxPerGallonFederal: '',
    avgGasolineTaxPerGallonState: '',
    tobaccoTaxPackOf20Federal: '1.01',
    tobaccoTaxPackOf20State: '0.62',
    tobaccoTaxOnOtpOfWholesaleCostFederal: 'N/A',
    tobaccoTaxOnOtpOfWholesaleCostState: '6.6',
    tobaccoTaxPerLargeCigarFederal: '0.4026',
    tobaccoTaxPerLargeCigarState: 'N/A',
    tobaccoTaxOnPipePerPoundFederal: '2.83',
    tobaccoTaxOnPipePerPoundState: 'N/A',
    tobaccoTaxOnRyoPerPoundFederal: '24.78',
    tobaccoTaxOnRyoPerPoundState: 'N/A',
    beerTaxPerGallonFederal: '0.43',
    beerTaxPerGallonState: '1.29'
  });
  businessTaxData.avgGasolineTaxPerGallonFederal =
    (+businessTaxData.gasolineTaxPerGallonFederal +
      +businessTaxData.dieselTaxPerGallonFederal) /
    2;
  businessTaxData.avgGasolineTaxPerGallonState =
    (+businessTaxData.gasolineTaxPerGallonState +
      +businessTaxData.dieselTaxPerGallonState) /
    2;
  const [payrollData, setPayrollData]: any = useState({
    eftpsPin: '',
    withHoldingNumber: '',
    laborAccNumber: '',
    payrollSchedule: '',
    payrollDeliveryMethod: '',
    payrollProcessingDay: '',
    payrollStartDate: null,
    importantNotes: '',
    contactName: '',
    contactNumber: '',
    contactEmail: ''
  });
  const [clientOwnershipData, setClientOwnershipData]: any = useState({
    ssnFein: '',
    fullLegalName: '',
    title: '',
    type: '',
    ownershipPercent: ''
  });
  const tabs = [
    { label: 'Basic', condition: true },
    { label: 'Sales Tax', condition: !!clientData.salesTax },
    { label: 'Business Tax', condition: !!clientData.businessTax },
    { label: 'Payroll', condition: !!clientData.payroll },
    { label: 'Ownership', condition: true },
    { label: 'Notes', condition: true }
    // Add more tabs as needed
  ];
  const visibleTabs = tabs.filter((tab) => tab.condition);
  const [validationErrors, setValidationErrors]: any = useState({});

  useEffect(() => {
    // If client object is provided, populate the form fields
    if (client) {
      console.log(client);
      setClientData(client);
      if (client.salesTaxes) {
        setClientSalesTaxData(client.salesTaxes);
      }
      if (client.businessTaxes) {
        setBusinessTaxData(client.businessTaxes);
      }
      if (client.payrolls) {
        setPayrollData(client.payrolls);
      }
      if (client.ownerships && client.ownerships.length) {
        setRows(client.ownerships);
      }
      // if (client.notes.length) {
      //   setRows(client.notes);
      // }
    } else {
      emptyAllFields();
    }
    getEmployees();
  }, [client]);
  useEffect(() => {}, [rows]);

  const emptyAllFields = () => {
    onClose();
    getEmployees();
    setTabValue(0);
    setTabName(visibleTabs[0].label);
    setClientData({
      clientId: '',
      federalTaxId: '',
      businessStructure: '',
      taxClassification: '',
      legalBusinessName: '',
      doingBusinessAs: '',
      industry: '',
      otherIndustry: '',
      sosNumber: '',
      businessFormationDate: null,
      streetAddress1: '',
      streetAddress2: '',
      city: '',
      county: '',
      state: '',
      zipCode: '',
      mailingAddressSameAsLocation: false,
      mailingStreetAddress1: '',
      mailingStreetAddress2: '',
      mailingCity: '',
      mailingCounty: '',
      mailingState: '',
      mailingZipCode: '',
      registeredAgent: '',
      contactPerson: '',
      contactNumber: '',
      contactEmail: '',
      contactFax: '',
      property: false,
      salesTax: false,
      businessTax: false,
      franchiseTax: false,
      franchiseTaxNumber: '',
      payroll: false,
      inactive: false,
      inactiveDate: null,
      MonthlyFees: null,
      annualReport: false,
      bookKeeping: false,
      taxReturnPreparation: false,
      tppReturnPreparation: false,
      monthlyFeesStartDate: null,
      annualReportDueDate: null,
      taxReturnDueDate: null,
      tppReturnDueDate: null,
      reasonForInactive: null,
      otherReason: '',
      accountantName: '',
      additionalNotes: ''
    });
    setClientSalesTaxData({
      salesTaxId: '',
      routingNumber: '',
      accountNumber: '',
      stateHighTax: '',
      stateLowTax: '',
      cityCountyTax: '',
      countyTax: '',
      cityTax: '',
      liquorTax: '',
      beerTax: '',
      cbdTax: '',
      specialTax: '',
      fees911: '',
      discountRate: '',
      contactName: '',
      contactNumber: '',
      contactEmail: '',
      salesStartDate: null,
      importantNotes: ''
    });
    setBusinessTaxData({
      businessTaxId: '',
      locationId: '',
      businessType: '',
      classification: '',
      taxRate: '',
      gasolineTaxPerGallonFederal: '0.184',
      gasolineTaxPerGallonState: '0.274',
      dieselTaxPerGallonFederal: '0.244',
      dieselTaxPerGallonState: '0.284',
      avgGasolineTaxPerGallonFederal: '',
      avgGasolineTaxPerGallonState: '',
      tobaccoTaxPackOf20Federal: '1.01',
      tobaccoTaxPackOf20State: '0.62',
      tobaccoTaxOnOtpOfWholesaleCostFederal: 'N/A',
      tobaccoTaxOnOtpOfWholesaleCostState: '6.6',
      tobaccoTaxPerLargeCigarFederal: '0.4026',
      tobaccoTaxPerLargeCigarState: 'N/A',
      tobaccoTaxOnPipePerPoundFederal: '2.83',
      tobaccoTaxOnPipePerPoundState: 'N/A',
      tobaccoTaxOnRyoPerPoundFederal: '24.78',
      tobaccoTaxOnRyoPerPoundState: 'N/A',
      beerTaxPerGallonFederal: '0.43',
      beerTaxPerGallonState: '1.29'
    });
    setPayrollData({
      eftpsPin: '',
      withHoldingNumber: '',
      laborAccNumber: '',
      payrollSchedule: [],
      payrollDeliveryMethod: '',
      payrollProcessingDay: '',
      payrollStartDate: null,
      importantNotes: '',
      contactName: '',
      contactNumber: '',
      contactEmail: ''
    });
    setClientOwnershipData({
      ssnFein: '',
      fullLegalName: '',
      title: '',
      type: '',
      ownershipPercent: ''
    });
    setRows([]);
  };
  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    // Do something with the new tab label
    if (validateFields()) {
      setTabValue(newValue);
      setTabName(visibleTabs[newValue].label);
    }
  };
  const getEmployees = async () => {
    const employeesResponse: any = await getAllEmployees();
    setEmployees(employeesResponse.data);
  };

  const handleNextButtonClick = async (event: React.ChangeEvent<{}>) => {
    try {
      setLoading(true);

      if (tabValue < visibleTabs.length - 1) {
        const response = await handleSaveButtonClick(event);

        if (response) {
          console.log('Response:', response);
          setTabValue((prevTabValue) => prevTabValue + 1);
          setTabName(visibleTabs[tabValue + 1].label);
        }
      }
    } finally {
      setLoading(false); // Stop the loader, whether successful or not
    }
  };

  const handleEdit = (index: number) => {
    setOwnershipAction('edit');
    setClientOwnershipData(rows[index]);
    setEditOwnershipIndex(index);
  };
  const handleDelete = (index: number) => {
    console.log(index);
    rows.splice(index, 1);
    setRows([...rows]);
  };
  const validateOwnership = async () => {
    let errors = {};
    const ownershipDataErrors = await validateOwnershipData(
      clientOwnershipData
    );
    errors = { ...errors, ...ownershipDataErrors };
    await setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleAddOwnership = async () => {
    setOwnershipAction('add');
    const isValid = await validateOwnership();
    if (isValid) {
      // clientOwnershipData
      console.log(rows);
      const ownershipPercentValues = rows.map(
        (item) => Number(item.ownershipPercent) || 0
      );
      const sumOfOwnershipPercent = ownershipPercentValues.reduce(
        (sum, value) => sum + value,
        0
      );
      console.log(sumOfOwnershipPercent);
      if (sumOfOwnershipPercent + +clientOwnershipData.ownershipPercent > 100) {
        NotificationManager.error(
          'Ownership Percent should not be greater than 100',
          'Error',
          3000
        );
        return;
      }
      setRows([...rows, clientOwnershipData]);
      // rows.push({...clientOwnershipData})

      setOwnerShipFormEmpty();
    } else {
      NotificationManager.error('Validation Failed!!', 'Error', 3000);
    }
  };

  const handleUpdateOwnership = () => {
    // rows.splice(editOwnershipIndex, 1);
    const ownershipPercentValues = rows.map(
      (item) => Number(item.ownershipPercent) || 0
    );
    const sumOfOwnershipPercent = ownershipPercentValues.reduce(
      (sum, value) => sum + value,
      0
    );
    if (
      sumOfOwnershipPercent +
        +clientOwnershipData.ownershipPercent -
        ownershipPercentValues[editOwnershipIndex] >
      100
    ) {
      NotificationManager.error(
        'Ownership Percent should not be greater than 100',
        'Error',
        3000
      );
      return;
    }
    rows[editOwnershipIndex] = clientOwnershipData;
    setRows(rows);
    setOwnerShipFormEmpty();
    setOwnershipAction('add');
  };
  const setOwnerShipFormEmpty = () => {
    setClientOwnershipData({
      ssnFein: '',
      fullLegalName: '',
      title: '',
      type: '',
      ownershipPercent: ''
    });
  };

  const handleSaveButtonClick = async (event: React.ChangeEvent<{}>) => {
    console.log(clientData);

    try {
      setLoading(true); // Start the loader
      const isValid = await validateFields();
      // const isValid = true;
      if (tabName !== 'Basic' && !clientData.id) {
        NotificationManager.error('Client not found', 'Error', 3000);
        return false;
      }
      if (isValid) {
        if (tabName === 'Basic') {
          try {
            const client: any = await registerClient(clientData);
            if (client.meta.status === 200) {
              setClientData(client.data);
              setNewClient(client.data);
              NotificationManager.success(
                'Client Registered successfully',
                'Success',
                3000
              );
              return true;
            }
          } catch (error) {
            NotificationManager.error(error.message, 'Error', 3000);
            return false;
          }
        } else if (tabName === 'Sales Tax') {
          try {
            const client: any = await addSalesTax({
              ...clientSalesTaxData,
              clientId: clientData.id
            });
            if (client.meta.status === 200) {
              NotificationManager.success(
                'Sales tax added successfully',
                'Success',
                3000
              );
              return true;
            }
          } catch (error) {
            NotificationManager.error(error.message, 'Error', 3000);
            return false;
          }
        } else if (tabName === 'Business Tax') {
          try {
            const client: any = await addBusinessTax({
              ...businessTaxData,
              clientId: clientData.id
            });
            if (client.meta.status === 200) {
              NotificationManager.success(
                'Business tax added successfully',
                'Success',
                3000
              );
              return true;
            }
          } catch (error) {
            NotificationManager.error('Business tax failed', 'Error', 3000);
            return false;
          }
        } else if (tabName === 'Payroll') {
          try {
            setPayrollData({
              ...payrollData
            });

            const client: any = await addPayroll({
              ...payrollData,
              clientId: clientData.id
            });
            if (client && client.meta.status === 200) {
              NotificationManager.success(
                'payroll added successfully',
                'Success',
                3000
              );
              return true;
            }
          } catch (error) {
            console.log(error);

            NotificationManager.error('payroll failed', 'Error', 3000);
            return false;
          }
        } else if (tabName === 'Ownership') {
          try {
            console.log(rows);
            const client: any = await addOwnership({
              rows,
              clientId: clientData.id
            });
            if (client.meta.status === 200) {
              NotificationManager.success(
                'Ownership added successfully',
                'Success',
                3000
              );
              return true;
            }
          } catch (error) {
            NotificationManager.error('Ownership failed', 'Error', 3000);
            return false;
          }
        } else if (tabName === 'Notes') {
          try {
            await addAdditionalNotes({
              additionalNotes: clientData.additionalNotes,
              clientId: clientData.id
            });
            NotificationManager.success(
              'Notes added successfully',
              'Success',
              3000
            );
            onClose();
            return true;
          } catch (error) {
            console.log(error);
            NotificationManager.error('Notes failed', 'Error', 3000);
            return false;
          }
        }
      } else {
        console.log(validationErrors);

        NotificationManager.error('Validation Failed!!', 'Error', 3000);
      }
    } finally {
      setLoading(false); // Stop the loader, whether successful or not
    }
  };

  const handlePreviousButtonClick = (event: React.ChangeEvent<{}>) => {
    if (tabValue > 0) {
      setTabValue(tabValue - 1);
      setTabName(visibleTabs[tabValue - 1].label);
    }
  };

  const handleInputChange = (field, value) => {
    console.log(field, value);
    if (tabName === 'Basic') {
      setClientData((prevData) => ({
        ...prevData,
        [field]: value
      }));
      if (field === 'mailingAddressSameAsLocation' && value === 'true') {
        setClientData((prevData) => ({
          ...prevData,
          mailingStreetAddress1: prevData.streetAddress1,
          mailingStreetAddress2: prevData.streetAddress2,
          mailingCity: prevData.city,
          mailingCounty: prevData.county,
          mailingState: prevData.state,
          mailingZipCode: prevData.zipCode
        }));
      }
    }
    if (tabName === 'Sales Tax') {
      setClientSalesTaxData((prevData) => ({
        ...prevData,
        [field]: value
      }));
    }
    if (tabName === 'Business Tax') {
      setBusinessTaxData((prevData) => ({
        ...prevData,
        [field]: value
      }));
    }
    if (tabName === 'Payroll') {
      setPayrollData((prevData) => ({
        ...prevData,
        [field]: value
      }));
    }
    if (tabName === 'Ownership') {
      setClientOwnershipData((prevData) => ({
        ...prevData,
        [field]: value
      }));
    }
    if (tabName === 'Notes') {
      setClientData((prevData) => ({
        ...prevData,
        [field]: value
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      console.log('Submitting:', clientData);
      handleSaveButtonClick(event);
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };

  const validateFields = async () => {
    let errors = {};

    if (tabName === 'Basic') {
      const basicDataErrors = await validateBasicData(clientData);
      errors = { ...errors, ...basicDataErrors };
      await setValidationErrors(errors);
      return Object.keys(errors).length === 0;
    } else if (tabName === 'Sales Tax') {
      const salesTaxDataErrors = await validateSalesTaxData(clientSalesTaxData);
      errors = { ...errors, ...salesTaxDataErrors };
      await setValidationErrors(errors);
      return Object.keys(errors).length === 0;
    } else if (tabName === 'Business Tax') {
      const businessTaxDataErrors = await validateBusinessTaxData(
        businessTaxData
      );
      errors = { ...errors, ...businessTaxDataErrors };
      await setValidationErrors(errors);
      return Object.keys(errors).length === 0;
    } else if (tabName === 'Payroll') {
      const payrollDataErrors = await validatePayrollData(payrollData);
      errors = { ...errors, ...payrollDataErrors };
      await setValidationErrors(errors);
      return Object.keys(errors).length === 0;
    } else if (tabName === 'Ownership') {
      return Object.keys(errors).length === 0;
    } else if (tabName === 'Notes') {
      return Object.keys(errors).length === 0;
    }

    return false;
  };

  return (
    <>
      {' '}
      {loading && (
        <div
          className="loader-wrapper"
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 9999
          }}
        >
          <BarLoader
            color={'#19d2d2'}
            loading={loading}
            style={{ width: 'inherit' }}
          />
        </div>
      )}
      <Modal
        open={open}
        onClose={() => {}}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backdropFilter: 'blur(8px)', // Adjust blur intensity as needed
          WebkitBackdropFilter: 'blur(8px)'
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            // top: '10%', // Adjust as needed
            left: '50%',
            transform: 'translateX(-50%)',
            width: '90%',
            height: '85%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '10px',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              display: 'none' // Hide scrollbar for Chrome/Safari
            },
            msOverflowStyle: 'none', // Hide scrollbar for IE and Edge
            scrollbarWidth: 'none'
          }}
        >
          <CloseIcon
            sx={{
              position: 'fixed',
              top: 10,
              right: 10,
              cursor: 'pointer',
              zIndex: 2000
            }}
            onClick={() => emptyAllFields()}
          />

          <AppBar
            position="sticky"
            sx={{
              top: 0,
              zIndex: 1,
              marginBottom: 2,
              backgroundColor: 'whitesmoke',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              width: 'auto',
              borderRadius: '10px'
            }}
          >
            <Tabs
              value={tabValue}
              onChange={handleChangeTab}
              variant="scrollable"
              sx={{
                '@media (max-width: 600px)': {
                  '.MuiTab-root': {
                    flexGrow: 1,
                    minWidth: '20%'
                  }
                }
              }}
            >
              {visibleTabs.map((tab) => (
                <Tab key={tab.label} label={tab.label} />
              ))}
              {/* Add more tabs as needed */}
            </Tabs>
          </AppBar>

          <Typography
            variant="h3"
            component="div"
            sx={{ marginBottom: 2, color: '#1976D2' }}
          >
            Add Client
          </Typography>

          <form onSubmit={handleSubmit}>
            {tabName === 'Basic' && (
              <>
                <Grid container spacing={2}>
                  {/* <TextInput
                    value={clientData.clientId}
                    label="Client ID"
                    placeholder="A-00001"
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.clientId}
                    name="clientId"
                    required={true}
                    readOnly={action === 'edit'}s
                  /> */}
                  <TextInput
                    value={clientData.federalTaxId}
                    label="Federal Tax ID"
                    placeholder="12-3456789"
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.federalTaxId}
                    name="federalTaxId"
                    required={true}
                  />
                  <Dropdown
                    value={clientData.businessStructure}
                    label=" Business Structure"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.businessStructure}
                    name="businessStructure"
                    options={businessStructures}
                  />
                  <Dropdown
                    value={clientData.taxClassification}
                    label="Tax Classification"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.taxClassification}
                    name="taxClassification"
                    options={taxClassifications}
                  />
                  <TextInput
                    value={clientData.legalBusinessName}
                    label="Legal Business Name"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.legalBusinessName}
                    name="legalBusinessName"
                  />
                  <TextInput
                    value={clientData.doingBusinessAs}
                    label="Doing Business As"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.doingBusinessAs}
                    name="doingBusinessAs"
                  />

                  <Dropdown
                    value={clientData.industry}
                    label="Industry"
                    placeholder="Select Industry"
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.industry}
                    name="industry"
                    options={industries}
                  />

                  {clientData.industry === 'Other-Specify' && (
                    <TextInput
                      value={clientData.otherIndustry}
                      label="Other Industry"
                      placeholder=""
                      handleInputChange={handleInputChange}
                      validationField={validationErrors.otherIndustry}
                      name="otherIndustry"
                    />
                  )}

                  <TextInput
                    value={clientData.sosNumber}
                    label="SOS #"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.sosNumber}
                    name="sosNumber"
                  />
                  <Grid item xs={12} md={4}>
                    <InputLabel htmlFor="businessFormationDate">
                      Business Formation Date
                    </InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Box sx={{ width: '100%' }}>
                        <DatePicker
                          value={clientData.businessFormationDate || null}
                          onChange={(date) =>
                            setClientData({
                              ...clientData,
                              businessFormationDate:
                                date instanceof Date && !isNaN(date.getTime())
                                  ? date.toISOString()
                                  : null
                            })
                          }
                          renderInput={(props) => (
                            <TextField {...props} style={{ width: '100%' }} />
                          )}
                          InputProps={{
                            size: 'small',
                            name: 'businessFormationDate'
                          }}
                        />
                      </Box>
                    </LocalizationProvider>
                  </Grid>
                  <FormHeading text="Location Address" />

                  <TextInput
                    value={clientData.streetAddress1}
                    label="Street Address 1"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.streetAddress1}
                    name="streetAddress1"
                  />
                  <TextInput
                    value={clientData.streetAddress2}
                    label="Street Address 2"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.streetAddress2}
                    name="streetAddress2"
                  />
                  <TextInput
                    value={clientData.city}
                    label="City"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.city}
                    name="city"
                  />
                  <TextInput
                    value={clientData.county}
                    label="County"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.county}
                    name="county"
                  />
                  <Dropdown
                    value={clientData.state}
                    label="State"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.state}
                    name="state"
                    options={states}
                  />
                  <TextInput
                    value={clientData.zipCode}
                    label="Zip Code"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.zipCode}
                    name="zipCode"
                  />
                  <FormHeading text="Mailing Address" />

                  <RadioYesNo
                    label="Same as Location Address ?"
                    value={clientData.mailingAddressSameAsLocation}
                    handleInputChange={handleInputChange}
                    name="mailingAddressSameAsLocation"
                  />

                  <TextInput
                    value={clientData.mailingStreetAddress1}
                    label="Street Address 1"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.mailingStreetAddress1}
                    name="mailingStreetAddress1"
                  />
                  <TextInput
                    value={clientData.mailingStreetAddress2}
                    label="Street Address 2"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.mailingStreetAddress2}
                    name="mailingStreetAddress2"
                  />
                  <TextInput
                    value={clientData.mailingCity}
                    label="City"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.mailingCity}
                    name="mailingCity"
                  />
                  <TextInput
                    value={clientData.mailingCounty}
                    label="County"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.mailingCounty}
                    name="mailingCounty"
                  />
                  <Dropdown
                    value={clientData.mailingState}
                    label="State"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.mailingState}
                    name="mailingState"
                    options={states}
                  />
                  <TextInput
                    value={clientData.mailingZipCode}
                    label="Zip Code"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.mailingZipCode}
                    name="mailingZipCode"
                  />

                  <FormHeading text="" />

                  <TextInput
                    value={clientData.registeredAgent}
                    label="Registered Agent"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.registeredAgent}
                    name="registeredAgent"
                  />
                </Grid>

                <Grid container spacing={2}>
                  <FormHeading text="" />

                  <TextInput
                    value={clientData.contactPerson}
                    label="Contact Person"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.contactPerson}
                    name="contactPerson"
                  />
                  <TextInput
                    value={clientData.contactNumber}
                    label="Contact Number"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.contactNumber}
                    name="contactNumber"
                  />
                  <TextInput
                    value={clientData.contactEmail}
                    label="Contact Email"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.contactEmail}
                    name="contactEmail"
                  />
                  <TextInput
                    value={clientData.contactFax}
                    label="Contact Fax"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.contactFax}
                    name="contactFax"
                  />
                </Grid>
                <Grid container spacing={2}>
                  <FormHeading text="" />
                  <Dropdown
                    value={clientData.accountantName}
                    label="Accountant Name"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.accountantName}
                    name="accountantName"
                    options={employees.map(
                      (employee) => employee.firstName + ' ' + employee.lastName
                    )}
                  />
                </Grid>

                <Grid container spacing={2}>
                  <FormHeading text="" />
                  <CheckBox
                    label="Property"
                    checked={clientData.property}
                    handleInputChange={handleInputChange}
                    name="property"
                  />
                  <CheckBox
                    label="Sales Tax"
                    checked={clientData.salesTax}
                    handleInputChange={handleInputChange}
                    name="salesTax"
                  />
                  <CheckBox
                    label="Business Tax "
                    checked={clientData.businessTax}
                    handleInputChange={handleInputChange}
                    name="businessTax"
                  />
                  <CheckBox
                    label="Franchise Tax "
                    checked={clientData.franchiseTax}
                    handleInputChange={handleInputChange}
                    name="franchiseTax"
                  />
                  <CheckBox
                    label="Payroll"
                    checked={clientData.payroll}
                    handleInputChange={handleInputChange}
                    name="payroll"
                  />
                  <CheckBox
                    label="Annual Report"
                    checked={clientData.annualReport}
                    handleInputChange={handleInputChange}
                    name="annualReport"
                  />
                  <CheckBox
                    label="Book Keeping"
                    checked={clientData.bookKeeping}
                    handleInputChange={handleInputChange}
                    name="bookKeeping"
                  />
                  <CheckBox
                    label="Tax Return Preparation"
                    checked={clientData.taxReturnPreparation}
                    handleInputChange={handleInputChange}
                    name="taxReturnPreparation"
                  />
                  <CheckBox
                    label="TPP Return Preparation"
                    checked={clientData.tppReturnPreparation}
                    handleInputChange={handleInputChange}
                    name="tppReturnPreparation"
                  />
                  <CheckBox
                    label="Inactive"
                    checked={clientData.inactive}
                    handleInputChange={handleInputChange}
                    name="inactive"
                  />
                </Grid>
                <Grid container spacing={2}>
                  <TextInput
                    value={clientData.monthlyFees}
                    label="Monthly Fees"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.monthlyFees}
                    name="monthlyFees"
                  />
                  <Grid item xs={12} md={4}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={
                          clientData.monthlyFeesStartDate || null}
                        onChange={(date: Date | null) => {
                          setClientData({
                            ...clientData,
                            monthlyFeesStartDate: date instanceof Date && !isNaN(date.getTime())
                            ? date.toISOString()
                            : null
                          });
                        }}
                        renderInput={(props) => (
                          <>
                            <InputLabel htmlFor="monthlyFeesStartDate">
                              Monthly Fees Start Date
                            </InputLabel>
                            <TextField {...props} style={{ width: '100%' }} />
                          </>
                        )}
                        InputProps={{
                          size: 'small',
                          name: 'monthlyFeesStartDate'
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  {!!clientData.franchiseTax && (
                    <React.Fragment>
                      <Grid item xs={12}>
                        <FormHeading text="" />
                      </Grid>
                      <TextInput
                        value={clientData.franchiseTaxNumber}
                        label="Franchise Tax Number"
                        placeholder=""
                        handleInputChange={handleInputChange}
                        validationField={validationErrors.franchiseTaxNumber}
                        name="franchiseTaxNumber"
                      />
                    </React.Fragment>
                  )}
                  {!!clientData.annualReport && (
                    <Grid item xs={12} md={4}>
                      <InputLabel htmlFor="annualReportDueDate">
                        Annual Report Due Date
                      </InputLabel>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          // label="Select Date"
                          value={clientData.annualReportDueDate || null}
                          onChange={(date: Date | null) =>
                            setClientData({
                              ...clientData,
                              annualReportDueDate:
                                date instanceof Date && !isNaN(date.getTime())
                                  ? date.toISOString()
                                  : null
                            })
                          }
                          renderInput={(props) => (
                            <TextField {...props} style={{ width: '100%' }} />
                          )}
                          InputProps={{
                            size: 'small',
                            name: 'annualReportDueDate'
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                  )}

                  {!!clientData.taxReturnPreparation && (
                    <Grid item xs={12} md={4}>
                      <InputLabel htmlFor="taxReturnDueDate">
                        Tax Return Due Date
                      </InputLabel>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          // label="Select Date"
                          value={clientData.taxReturnDueDate || null}
                          onChange={(date: Date | null) =>
                            setClientData({
                              ...clientData,
                              taxReturnDueDate:
                                date instanceof Date && !isNaN(date.getTime())
                                  ? date.toISOString()
                                  : null
                            })
                          }
                          renderInput={(props) => (
                            <TextField {...props} style={{ width: '100%' }} />
                          )}
                          InputProps={{
                            size: 'small',
                            name: 'taxReturnDueDate'
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                  )}

                  {!!clientData.tppReturnPreparation && (
                    <Grid item xs={12} md={4}>
                      <InputLabel htmlFor="tppReturnPreparationDate">
                        TPP Return Due Date
                      </InputLabel>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          // label="Select Date"
                          value={clientData.tppReturnDueDate || null}
                          onChange={(date: Date | null) =>
                            setClientData({
                              ...clientData,
                              tppReturnDueDate:
                                date instanceof Date && !isNaN(date.getTime())
                                  ? date.toISOString()
                                  : null
                            })
                          }
                          renderInput={(props) => (
                            <TextField {...props} style={{ width: '100%' }} />
                          )}
                          InputProps={{
                            size: 'small',
                            name: 'tppReturnDueDate'
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                  )}
                </Grid>

                {!!clientData.inactive && (
                  <Grid container spacing={2}>
                    <FormHeading text="" />
                    <Dropdown
                      value={clientData.reasonForInactive}
                      label="Reason for Inactive"
                      placeholder="Select a reason"
                      handleInputChange={handleInputChange}
                      validationField={validationErrors.reasonForInactive}
                      name="reasonForInactive"
                      options={reasonsForInactive}
                    />

                    {clientData.reasonForInactive === 'Other -Specify' && (
                      <TextInput
                        value={clientData.otherReason}
                        label="Other -Specify"
                        placeholder=""
                        handleInputChange={handleInputChange}
                        validationField={validationErrors.otherReason}
                        name="otherReason"
                      />
                    )}
                    <Grid item xs={12} md={4}>
                      <InputLabel htmlFor="inactiveDate">
                        In-Active Date
                      </InputLabel>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          // label="Select Date"
                          value={clientData.inactiveDate}
                          onChange={(date: Date | null) =>
                            setClientData({
                              ...clientData,
                              inactiveDate:
                                date instanceof Date && !isNaN(date.getTime())
                                  ? date.toISOString()
                                  : null
                            })
                          }
                          renderInput={(props) => (
                            <TextField {...props} style={{ width: '100%' }} />
                          )}
                          InputProps={{ size: 'small', name: 'inactiveDate' }}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                )}
              </>
            )}

            {/* Sales Tax Tab Content */}
            {tabName === 'Sales Tax' && (
              <>
                <Grid container spacing={2}>
                  <TextInput
                    value={clientData.state}
                    label="State"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.state}
                    name="state"
                    props={{ readOnly: true }}
                  />
                  <TextInput
                    value={clientData.county}
                    label="County"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.county}
                    name="county"
                    props={{ readOnly: true }}
                  />
                  <TextInput
                    value={clientData.city}
                    label="City"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.city}
                    name="city"
                    props={{ readOnly: true }}
                  />
                  <TextInput
                    value={clientSalesTaxData.salesTaxId}
                    label="Sales Tax ID"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.salesTaxId}
                    name="salesTaxId"
                  />
                  <TextInput
                    value={clientSalesTaxData.routingNumber}
                    label="Routing #"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.routingNumber}
                    name="routingNumber"
                  />
                  <TextInput
                    value={clientSalesTaxData.accountNumber}
                    label="Account #"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.accountNumber}
                    name="accountNumber"
                  />
                </Grid>

                <Grid container spacing={2} mt={3}>
                  <FormHeading text="" />
                  <TextInput
                    value={clientSalesTaxData.stateHighTax}
                    label="State High Tax"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.stateHighTax}
                    name="stateHighTax"
                    props={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      )
                    }}
                  />
                  <TextInput
                    value={clientSalesTaxData.stateLowTax}
                    label="State Low Tax"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.stateLowTax}
                    name="stateLowTax"
                    props={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      )
                    }}
                  />
                </Grid>

                <Grid container spacing={2} mt={3}>
                  <TextInput
                    value={clientSalesTaxData.cityCountyTax}
                    label="City/County Taxes"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.cityCountyTax}
                    name="cityCountyTax"
                    props={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      )
                    }}
                  />
                  <TextInput
                    value={clientSalesTaxData.countyTax}
                    label="County Tax"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.countyTax}
                    name="countyTax"
                    props={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      )
                    }}
                  />
                  <TextInput
                    value={clientSalesTaxData.cityTax}
                    label="City Tax"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.cityTax}
                    name="cityTax"
                    props={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      )
                    }}
                  />
                  <TextInput
                    value={clientSalesTaxData.liquorTax}
                    label="Liquor Tax"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.liquorTax}
                    name="liquorTax"
                    props={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      )
                    }}
                  />
                  <TextInput
                    value={clientSalesTaxData.beerTax}
                    label="Beer Tax"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.beerTax}
                    name="beerTax"
                    props={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      )
                    }}
                  />
                  <TextInput
                    value={clientSalesTaxData.cbdTax}
                    label="CBD Tax"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.cbdTax}
                    name="cbdTax"
                    props={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      )
                    }}
                  />
                  <TextInput
                    value={clientSalesTaxData.specialTax}
                    label="Special Tax"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.specialTax}
                    name="specialTax"
                    props={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      )
                    }}
                  />
                  <TextInput
                    value={clientSalesTaxData.fees911}
                    label="911 Fees"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.fees911}
                    name="fees911"
                    props={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      )
                    }}
                  />
                  <TextInput
                    value={clientSalesTaxData.discountRate}
                    label="Discount Rate"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.discountRate}
                    name="discountRate"
                    props={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid container spacing={2} mt={3}>
                  <Grid item xs={12} md={6}>
                    <InputLabel htmlFor="importantNotes">
                      Important Notes
                    </InputLabel>
                    <TextField
                      id="importantNotes"
                      variant="outlined"
                      multiline
                      rows={5}
                      fullWidth
                      size="small"
                      value={clientSalesTaxData.importantNotes}
                      onChange={(e) =>
                        handleInputChange('importantNotes', e.target.value)
                      }
                      error={!!validationErrors.importantNotes}
                      helperText={validationErrors.importantNotes}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={3}>
                  <TextInput
                    value={clientSalesTaxData.contactName}
                    label="Contact Name"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.contactName}
                    name="contactName"
                  />
                  <TextInput
                    value={clientSalesTaxData.contactNumber}
                    label="Contact Number"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.contactNumber}
                    name="contactNumber"
                  />
                  <TextInput
                    value={clientSalesTaxData.contactEmail}
                    label="Contact Email"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.contactEmail}
                    name="contactEmail"
                  />
                  <Grid item xs={12} md={4}>
                    <InputLabel htmlFor="salesStartDate">
                      Sales Start Date
                    </InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Box sx={{ width: '100%' }}>
                        <DatePicker
                          value={clientSalesTaxData.salesStartDate}
                          onChange={(date: Date | null) =>
                            setClientSalesTaxData({
                              ...clientSalesTaxData,
                              salesStartDate:
                                date instanceof Date && !isNaN(date.getTime())
                                  ? date.toISOString()
                                  : null
                            })
                          }
                          renderInput={(props) => (
                            <TextField
                              {...props}
                              style={{
                                width: '100%',
                                borderColor: !clientSalesTaxData.salesStartDate
                                  ? 'red'
                                  : ''
                              }}
                              error={!clientSalesTaxData.salesStartDate}
                              helperText={
                                !clientSalesTaxData.salesStartDate
                                  ? 'Sales tax date is required'
                                  : ''
                              }
                            />
                          )}
                          InputProps={{
                            size: 'small',
                            name: 'salesStartDate'
                          }}
                        />
                      </Box>
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </>
            )}

            {tabName === 'Business Tax' && (
              <>
                <Grid container spacing={2}>
                  <TextInput
                    value={clientData.state}
                    label="State"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.state}
                    name="state"
                    props={{ readOnly: true }}
                  />
                  <TextInput
                    value={clientData.county}
                    label="County"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.county}
                    name="county"
                    props={{ readOnly: true }}
                  />
                  <TextInput
                    value={clientData.city}
                    label="City"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.city}
                    name="city"
                    props={{ readOnly: true }}
                  />
                  <FormHeading text="" />
                  <TextInput
                    value={businessTaxData.businessTaxId}
                    label="Business Tax ID"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.businessTaxId}
                    name="businessTaxId"
                  />
                  <TextInput
                    value={businessTaxData.locationId}
                    label="Location ID"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.locationId}
                    name="locationId"
                  />
                </Grid>
                <Grid container spacing={2} mt={3}>
                  <FormHeading text="" />
                  <Dropdown
                    value={businessTaxData.businessType}
                    label="Business Type"
                    placeholder="Select Business Type"
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.businessType}
                    name="businessType"
                    options={businessTypes}
                  />
                  <Dropdown
                    value={businessTaxData.classification}
                    label="Classification"
                    placeholder="Select Business Classification"
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.classification}
                    name="classification"
                    options={classifications}
                  />
                  <TextInput
                    value={businessTaxData.taxRate}
                    label="Tax Rate"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.taxRate}
                    name="taxRate"
                  />
                </Grid>
                <Grid container spacing={2} mt={3}>
                  <FormHeading text="" />
                  <TextLabelSmall
                    text="Gasoline Tax per Gallon"
                    htmlFor="gasolineTaxPerGallonFederal "
                  />
                  <DoubleInput
                    value1={businessTaxData.gasolineTaxPerGallonFederal}
                    value2={businessTaxData.gasolineTaxPerGallonState}
                    label1={'Federal'}
                    label2={'State'}
                    handleInputChange={handleInputChange}
                    validation={validationErrors}
                    name1="gasolineTaxPerGallonFederal"
                    name2="gasolineTaxPerGallonState"
                    placeholder1=""
                    placeholder2=""
                    props={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      )
                    }}
                  />
                  <TextLabelSmall
                    text="Diesel Tax per Gallon"
                    htmlFor="dieselTaxPerGallonFederal "
                  />
                  <DoubleInput
                    value1={businessTaxData.dieselTaxPerGallonFederal}
                    value2={businessTaxData.dieselTaxPerGallonState}
                    label1={'Federal'}
                    label2={'State'}
                    handleInputChange={handleInputChange}
                    validation={validationErrors}
                    name1="dieselTaxPerGallonFederal"
                    name2="dieselTaxPerGallonState"
                    placeholder1=""
                    placeholder2=""
                    props={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      )
                    }}
                  />
                  <TextLabelSmall
                    text="Avg Gasoline Tax per Gallon"
                    htmlFor="avgGasolineTaxPerGallonFederal"
                  />
                  <DoubleInput
                    value1={businessTaxData.avgGasolineTaxPerGallonFederal}
                    value2={businessTaxData.avgGasolineTaxPerGallonState}
                    label1={'Federal'}
                    label2={'State'}
                    handleInputChange={handleInputChange}
                    validation={validationErrors}
                    name1="avgGasolineTaxPerGallonFederal"
                    name2="avgGasolineTaxPerGallonState"
                    placeholder1=""
                    placeholder2=""
                    props={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      readOnly: true
                    }}
                  />
                  <TextLabelSmall
                    text="Tobacco Tax on Cigs -Pack of 20"
                    htmlFor="tobaccoTaxPackOf20Federal"
                  />
                  <DoubleInput
                    value1={businessTaxData.tobaccoTaxPackOf20Federal}
                    value2={businessTaxData.tobaccoTaxPackOf20State}
                    label1={'Federal'}
                    label2={'State'}
                    handleInputChange={handleInputChange}
                    validation={validationErrors}
                    name1="tobaccoTaxPackOf20Federal"
                    name2="tobaccoTaxPackOf20State"
                    placeholder1=""
                    placeholder2=""
                    props={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      )
                    }}
                  />
                  <TextLabelSmall
                    text="Tobacco Tax on OTP - of wholesale cost"
                    htmlFor="tobaccoTaxOnOtpOfWholesaleCostFederal"
                  />
                  <DoubleInput
                    value1={
                      businessTaxData.tobaccoTaxOnOtpOfWholesaleCostFederal
                    }
                    value2={businessTaxData.tobaccoTaxOnOtpOfWholesaleCostState}
                    label1={'Federal'}
                    label2={'State'}
                    handleInputChange={handleInputChange}
                    validation={validationErrors}
                    name1="tobaccoTaxOnOtpOfWholesaleCostFederal"
                    name2="tobaccoTaxOnOtpOfWholesaleCostState"
                    placeholder1=""
                    placeholder2=""
                    props={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      )
                    }}
                  />
                  <TextLabelSmall
                    text="Tobacco Tax on Large Cigar - Per Cigar"
                    htmlFor="tobaccoTaxPerLargeCigarFederal"
                  />
                  <DoubleInput
                    value1={businessTaxData.tobaccoTaxPerLargeCigarFederal}
                    value2={businessTaxData.tobaccoTaxPerLargeCigarState}
                    label1={'Federal'}
                    label2={'State'}
                    handleInputChange={handleInputChange}
                    validation={validationErrors}
                    name1="tobaccoTaxPerLargeCigarFederal"
                    name2="tobaccoTaxPerLargeCigarState"
                    placeholder1=""
                    placeholder2=""
                    props={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      )
                    }}
                  />
                  <TextLabelSmall
                    text="Tobacco Tax on Pipe - Per pound"
                    htmlFor="tobaccoTaxOnPipePerPoundFederal"
                  />
                  <DoubleInput
                    value1={businessTaxData.tobaccoTaxOnPipePerPoundFederal}
                    value2={businessTaxData.tobaccoTaxOnPipePerPoundState}
                    label1={'Federal'}
                    label2={'State'}
                    handleInputChange={handleInputChange}
                    validation={validationErrors}
                    name1="tobaccoTaxOnPipePerPoundFederal"
                    name2="tobaccoTaxOnPipePerPoundState"
                    placeholder1=""
                    placeholder2=""
                    props={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      )
                    }}
                  />
                  <TextLabelSmall
                    text="Tobacco Tax on RYO - Per pound"
                    htmlFor="tobaccoTaxOnRyoPerPoundFederal"
                  />
                  <DoubleInput
                    value1={businessTaxData.tobaccoTaxOnRyoPerPoundFederal}
                    value2={businessTaxData.tobaccoTaxOnRyoPerPoundState}
                    label1={'Federal'}
                    label2={'State'}
                    handleInputChange={handleInputChange}
                    validation={validationErrors}
                    name1="tobaccoTaxOnRyoPerPoundFederal"
                    name2="tobaccoTaxOnRyoPerPoundState"
                    placeholder1=""
                    placeholder2=""
                    props={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      )
                    }}
                  />
                  <TextLabelSmall
                    text="Beer Tax per Gallon"
                    htmlFor="beerTaxPerGallonFederal"
                  />
                  <DoubleInput
                    value1={businessTaxData.beerTaxPerGallonFederal}
                    value2={businessTaxData.beerTaxPerGallonState}
                    label1={'Federal'}
                    label2={'State'}
                    handleInputChange={handleInputChange}
                    validation={validationErrors}
                    name1="beerTaxPerGallonFederal"
                    name2="beerTaxPerGallonState"
                    placeholder1=""
                    placeholder2=""
                    props={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      )
                    }}
                  />
                </Grid>
              </>
            )}

            {tabName === 'Payroll' && (
              <>
                <Grid container spacing={2}>
                  <TextInput
                    value={clientData.state}
                    label="State"
                    placeholder="TENNESSEE"
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.state}
                    name="state"
                    props={{ readOnly: true }}
                  />
                  <TextInput
                    value={clientData.county}
                    label="County"
                    placeholder="Shelby"
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.county}
                    name="county"
                    props={{ readOnly: true }}
                  />
                  <TextInput
                    value={clientData.city}
                    label="City"
                    placeholder="Collierville"
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.city}
                    name="city"
                    props={{ readOnly: true }}
                  />
                </Grid>
                <Grid container spacing={2}>
                  <FormHeading text="" />
                  <TextInput
                    value={payrollData.eftpsPin}
                    label="EFTPS PIN"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.eftpsPin}
                    name="eftpsPin"
                  />
                  <TextInput
                    value={payrollData.withHoldingNumber}
                    label="Withholding #"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.withHoldingNumber}
                    name="withHoldingNumber"
                  />
                  <TextInput
                    value={payrollData.laborAccNumber}
                    label="Labor A/c #"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.laborAccNumber}
                    name="laborAccNumber"
                  />
                </Grid>
                <Grid container spacing={2} mt={3}>
                  <FormHeading text="" />
                  <Dropdown
                    value={payrollData.payrollSchedule}
                    label="Payroll Schedule"
                    placeholder="Select Payroll Schedule"
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.payrollSchedule}
                    name="payrollSchedule"
                    options={payrollSchedules}
                  />
                  {/* <MultiSelectCheckbox
                    options={payrollSchedules}
                    label="Payroll Schedule"
                    value={payrollData.payrollSchedule}
                    name="payrollSchedule"
                    handleInputChange={handleInputChange}
                  /> */}
                  <Dropdown
                    value={payrollData.payrollDeliveryMethod}
                    label="Payroll Delivery Method"
                    placeholder="Select Payroll Delivery Method"
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.payrollDeliveryMethod}
                    name="payrollDeliveryMethod"
                    options={payrollDeliveryMethods}
                  />
                  <Dropdown
                    value={payrollData.payrollProcessingDay}
                    label="Payroll Processing Day"
                    placeholder="Select Payroll Processing Day"
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.payrollProcessingDay}
                    name="payrollProcessingDay"
                    options={payrollProcessingDays}
                  />
                  <Grid item xs={12} md={4}>
                    <InputLabel htmlFor="payrollStartDate">
                      Payroll Start Date
                    </InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Box sx={{ width: '100%' }}>
                        <DatePicker
                          value={payrollData.payrollStartDate}
                          onChange={(date: Date | null) =>
                            setPayrollData({
                              ...payrollData,
                              payrollStartDate:
                                date instanceof Date && !isNaN(date.getTime())
                                  ? date.toISOString()
                                  : null
                            })
                          }
                          renderInput={(props) => (
                            <TextField {...props} style={{ width: '100%' }} />
                          )}
                          InputProps={{
                            size: 'small',
                            name: 'payrollStartDate'
                          }}
                        />
                      </Box>
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={3}>
                  <Grid item xs={12} md={6}>
                    <InputLabel htmlFor="importantNotes">
                      Important Notes
                    </InputLabel>
                    <TextField
                      id="importantNotes"
                      variant="outlined"
                      multiline
                      rows={5}
                      fullWidth
                      size="small"
                      value={payrollData.importantNotes}
                      onChange={(e) =>
                        handleInputChange('importantNotes', e.target.value)
                      }
                      error={!!validationErrors.importantNotes}
                      helperText={validationErrors.importantNotes}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={3}>
                  <TextInput
                    value={payrollData.contactName}
                    label="Contact Name"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.contactName}
                    name="contactName"
                  />
                  <TextInput
                    value={payrollData.contactNumber}
                    label="Contact Number"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.contactNumber}
                    name="contactNumber"
                  />
                  <TextInput
                    value={payrollData.contactEmail}
                    label="Contact Email"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.contactEmail}
                    name="contactEmail"
                  />
                </Grid>
              </>
            )}

            {tabName === 'Ownership' && (
              <>
                <Grid container spacing={2}>
                  {/* Fields for Tab 2 */}
                  <TextInput
                    value={clientOwnershipData.ssnFein}
                    label="SSN/FEIN"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.ssnFein}
                    name="ssnFein"
                  />
                  <TextInput
                    value={clientOwnershipData.fullLegalName}
                    label="Full Legal Name"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.fullLegalName}
                    name="fullLegalName"
                  />
                  <TextInput
                    value={clientOwnershipData.title}
                    label="Title"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.title}
                    name="title"
                  />
                  <Dropdown
                    value={clientOwnershipData.type}
                    label="Type"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.type}
                    name="type"
                    options={ownershipTitles}
                  />

                  <TextInput
                    value={clientOwnershipData.ownershipPercent}
                    label="Ownership Percentage"
                    placeholder=""
                    handleInputChange={handleInputChange}
                    validationField={validationErrors.ownershipPercent}
                    name="ownershipPercent"
                    props={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Box
                  mt={2}
                  sx={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  {ownershipAction === 'add' && (
                    <Button
                      variant="contained"
                      color="success"
                      size="small"
                      onClick={handleAddOwnership}
                    >
                      Add
                    </Button>
                  )}
                  {ownershipAction === 'edit' && (
                    <Button
                      variant="contained"
                      color="success"
                      size="small"
                      onClick={handleUpdateOwnership}
                    >
                      Update
                    </Button>
                  )}

                  <Button
                    sx={{ marginLeft: 2 }}
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={() => setOwnerShipFormEmpty()}
                  >
                    Reset
                  </Button>
                </Box>
                {rows.length > 0 && (
                  <Box mt={3}>
                    <Paper
                      sx={{ width: '100%', overflow: 'hidden' }}
                      elevation={3}
                    >
                      <TableContainer sx={{ maxHeight: 400 }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              {columns.map((column) => (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{
                                    minWidth: column.minWidth,
                                    fontWeight: '800',
                                    color: '#1976D2'
                                  }}
                                >
                                  {column.label}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rows.map((row, index) => {
                              return (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={index}
                                >
                                  {columns.map((column) => {
                                    if (column.id !== 'actions') {
                                      return (
                                        <TableCell
                                          key={column.id}
                                          align={column.align}
                                        >
                                          {row[column.id]}
                                        </TableCell>
                                      );
                                    } else {
                                      return (
                                        <TableCell
                                          key={column.id}
                                          align={column.align}
                                        >
                                          <EditIcon
                                            onClick={() => handleEdit(index)}
                                            style={{
                                              color: '#1976D2',
                                              cursor: 'pointer'
                                            }}
                                          />
                                          <DeleteIcon
                                            onClick={() => handleDelete(index)}
                                            style={{
                                              color: '#1976D2',
                                              marginLeft: '8px',
                                              cursor: 'pointer'
                                            }}
                                          />
                                        </TableCell>
                                      );
                                    }
                                  })}
                                </TableRow>
                              );
                            })}
                            {rows.length > 0 && (
                              <TableRow>
                                
                                <TableCell colSpan={4} align="center">
                                  Total
                                </TableCell>
                                <TableCell align="left">
                                  99
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </Box>
                )}
              </>
            )}
            {tabName === 'Notes' && (
              <>
                <Grid container spacing={2} mt={3}>
                  <Grid item xs={12} md={12}>
                    <InputLabel htmlFor="additionalNotes">
                      Additional Notes
                    </InputLabel>
                    <TextField
                      id="additionalNotes"
                      variant="outlined"
                      multiline
                      rows={10}
                      fullWidth
                      size="small"
                      value={clientData.additionalNotes}
                      onChange={(e) =>
                        handleInputChange('additionalNotes', e.target.value)
                      }
                      error={!!validationErrors.additionalNotes}
                      helperText={validationErrors.additionalNotes}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            {/* Add more tabs and fields as needed */}
            {/* ... */}

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 4
              }}
            >
              <Box style={{ display: 'flex-start', justifyContent: 'start' }}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={emptyAllFields}
                >
                  Close
                </Button>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: tabValue === 0 ? 'flex-end' : 'space-between',
                  marginTop: 0
                }}
              >
                {tabValue > 0 && (
                  <Box>
                    <Button
                      variant="text"
                      color="primary"
                      onClick={handlePreviousButtonClick}
                    >
                      <ArrowBackOutlinedIcon /> Previous
                    </Button>
                  </Box>
                )}

                {tabValue < visibleTabs.length - 1 && (
                  <Box>
                    <Button
                      variant="text"
                      color="primary"
                      onClick={handleSaveButtonClick}
                    >
                      {action === 'add' ? 'Save' : 'Update'}{' '}
                      <SaveOutlinedIcon />
                    </Button>
                    <Button
                      variant="text"
                      color="primary"
                      onClick={handleNextButtonClick}
                    >
                      Save & Next <ArrowForwardOutlinedIcon />
                    </Button>
                  </Box>
                )}
                {tabValue == visibleTabs.length - 1 && (
                  <Button variant="contained" color="primary" type="submit">
                    Save <SaveOutlinedIcon />
                  </Button>
                )}
              </Box>
            </Box>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default ClientsForm;
