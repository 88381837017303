import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Grid,
  InputLabel,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BarLoader } from 'react-spinners';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SearchIcon from '@mui/icons-material/Search';
import { states } from 'src/constants/clients';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { getAllClients, getClientById } from 'src/services/clientService';
import { formatClientId } from 'src/utils/helperFunctions';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ViewClient from '../viewClient/viewClient';
import moment from 'moment';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'sno', label: 'S.No' },
  { id: 'clientId', label: 'Client ID' },
  { id: 'state', label: 'State' },
  { id: 'legalBusinessName', label: 'Legal Business Name', minWidth: 200 },
  {
    id: 'doingBusinessAs',
    label: 'Doing Business As',
    minWidth: 150
  },
  {
    id: 'contactPerson',
    label: 'Contact Person'
  },
  {
    id: 'contactEmail',
    label: 'Contact Email'
  },
  {
    id: 'contactNumber',
    label: 'Contact Number'
  },
  {
    id: 'accountantName',
    label: 'Accountant'
  },
  {
    id: 'actions',
    label: 'Actions',
    minWidth: 30,
    align: 'right'
  }
];

const ClientReport = () => {
  const [loading, setLoading] = useState(false);
  const [reversed, setReversed] = useState(false);
  const [selectedState, setSelectedState] = useState('All');
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedDateStart, setSelectedDateStart] = useState(null);
  const [selectedDateEnd, setSelectedDateEnd] = useState(null);
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [unSortedClients, setUnSortedClients] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [property, setProperty] = useState(false);
  const [salesTax, setSalesTax] = useState(false);
  const [businessTax, setBusinessTax] = useState(false);
  const [franchiseTax, setFranchiseTax] = useState(false);
  const [payroll, setPayroll] = useState(false);
  const [annualReport, setAnnualReport] = useState(false);
  const [bookKeeping, setBookKeeping] = useState(false);
  const [taxReturnPreparation, setTaxReturnPreparation] = useState(false);
  const [tPPreturnPreparation, setTPPReturnPreparation] = useState(false);

  const styles = {
    cell: {
      border: '1px solid #E3E1D9',
      borderCollapse: 'collapse',
      padding: '6px'
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClear = () => {
    setProperty(false);
    setSalesTax(false);
    setBusinessTax(false);
    setFranchiseTax(false);
    setPayroll(false);
    setAnnualReport(false);
    setBookKeeping(false);
    setTaxReturnPreparation(false);
    setTPPReturnPreparation(false);
    setSelectedState('All');
    setSelectedYear(null);
    setSelectedDateStart(null);
    setSelectedDateEnd(null);
  };

  const handleYearChangeChange = (date) => {
    setSelectedYear(date);
  };
  const handleStartDateChange = (date) => {
    setSelectedDateStart(date);
  };
  const handleEndDateChange = (date) => {
    setSelectedDateEnd(date);
  };

  const getClients = async () => {
    try {
      setLoading(true);
      const data = await getAllClients();
      console.log('data::', data);

      // Update the rows state with the new data
      setUnSortedClients(data?.data);
      setClients(data?.data);
      //   console.log({ clientRows });
      //   setUnFilteredClient(clientRows);
      //   if (data && data.data > 0 || unFilteredClient.length > 0) {
      //     handleFilter(selectedColumns, searchValue);
      //   }
      setLoading(false); // Set loader to false after successful fetching
    } catch (error) {
      setLoading(false); // Ensure loader is set to false on error
      console.error('Error fetching clients:', error);
    }
  };

  const handleView = async (id: number) => {
    try {
      setLoading(true);
      const client = await getClientById(id);
      if (client) {
        setClient(client.data);
        setLoading(false);
        setIsViewModalOpen(true);
      }
    } catch (error) {
      console.error('Error fetching clients:', error);
      setLoading(false);
    }
  };

  const closeViewModal = () => {
    setIsViewModalOpen(false);
    setClient(null);
  };

  const handleFilter = () => {
    if (
      property ||
      salesTax ||
      businessTax ||
      franchiseTax ||
      payroll ||
      annualReport ||
      bookKeeping ||
      taxReturnPreparation ||
      tPPreturnPreparation ||
      selectedState ||
      selectedYear ||
      selectedDateStart ||
      selectedDateEnd
    ) {
      let filteredClients = [];
      if(!!reversed){
        filteredClients = unSortedClients.filter((client) => {
          if (property && !!client.property) return false;
          if (salesTax && !!client.salesTax) return false;
          if (businessTax && !!client.businessTax) return false;
          if (franchiseTax && !!client.franchiseTax) return false;
          if (payroll && !!client.payroll) return false;
          if (annualReport && !!client.annualReport) return false;
          if (bookKeeping && !!client.bookKeeping) return false;
          if (taxReturnPreparation && !!client.taxReturnPreparation) return false;
          if (tPPreturnPreparation && !!client.tppReturnPreparation) return false;
          if (
            selectedState &&
            selectedState !== 'All' &&
            client?.state?.toLowerCase() !== selectedState.toLowerCase()
          )
            return false;
          if (
            selectedYear &&
            moment(client.businessFormationDate).format('YYYY') !==
              moment(selectedYear).format('YYYY')
          )
            return false;
            if (
              selectedDateStart && 
              moment(client.createdAt).startOf('day').isBefore(moment(selectedDateStart).startOf('day'))
            )
            return false;
            if (
              selectedDateEnd && 
              moment(client.createdAt).startOf('day').isAfter(moment(selectedDateEnd).startOf('day'))
            )
            return false;
            if (
              selectedDateStart && selectedDateEnd &&
              moment(client.createdAt).startOf('day').isBefore(moment(selectedDateStart).startOf('day')) &&
              moment(client.createdAt).startOf('day').isAfter(moment(selectedDateEnd).startOf('day'))
            )
            return false;
          return true;
        });
      }else{
        filteredClients = unSortedClients.filter((client) => {
          if (property && !client.property) return false;
          if (salesTax && !client.salesTax) return false;
          if (businessTax && !client.businessTax) return false;
          if (franchiseTax && !client.franchiseTax) return false;
          if (payroll && !client.payroll) return false;
          if (annualReport && !client.annualReport) return false;
          if (bookKeeping && !client.bookKeeping) return false;
          if (taxReturnPreparation && !client.taxReturnPreparation) return false;
          if (tPPreturnPreparation && !client.tppReturnPreparation) return false;
          if (
            selectedState &&
            selectedState !== 'All' &&
            client?.state?.toLowerCase() !== selectedState.toLowerCase()
          )
            return false;
          if (
            selectedYear &&
            moment(client.businessFormationDate).format('YYYY') !==
              moment(selectedYear).format('YYYY')
          )
            return false;
            if (
              selectedDateStart && 
              moment(client.createdAt).startOf('day').isBefore(moment(selectedDateStart).startOf('day'))
            )
            return false;
            if (
              selectedDateEnd && 
              moment(client.createdAt).startOf('day').isAfter(moment(selectedDateEnd).startOf('day'))
            )
            return false;
            if (
              selectedDateStart && selectedDateEnd &&
              moment(client.createdAt).startOf('day').isBefore(moment(selectedDateStart).startOf('day')) &&
              moment(client.createdAt).startOf('day').isAfter(moment(selectedDateEnd).startOf('day'))
            )
            return false;
          return true;
        });
      }

      setClients(filteredClients);
    } else {
      setClients(unSortedClients);
    }
  };

  const handleExportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    // Define columns
    worksheet.columns = [
      { header: 'S.No', key: 'sno', width: 6 },
      { header: 'Client Id', key: 'id', width: 12 },
      { header: 'State', key: 'state', width: 16 },
      { header: 'Legal Business Name', key: 'legalBusinessName', width: 30 },
      { header: 'Doing Business As', key: 'doingBusinessAs', width: 30 },
      { header: 'Contact person', key: 'contactPerson', width: 18 },
      { header: 'Contact Email', key: 'contactEmail', width: 18 },
      { header: 'Contact Number', key: 'contactNumber', width: 18 },
      { header: 'Accountant', key: 'accountant', width: 18 }
    ];

    worksheet.getRow(1).font = { bold: true };
    worksheet.getRow(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFF00' } // Yellow background
    };
    // Add rows
    clients.forEach((row) => {
      worksheet.addRow({
        sno: clients.indexOf(row) + 1,
        id: formatClientId(+row.id),
        state: row.state,
        legalBusinessName: row.legalBusinessName || '',
        doingBusinessAs: row.doingBusinessAs || '',
        contactPerson: row.contactPerson || '',
        contactEmail: row.contactEmail || '',
        contactNumber: row.contactNumber || '',
        accountant: row.accountantName || ''
      });
    });

    // Save to file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    saveAs(blob, 'clientReport.xlsx');
  };

  useEffect(() => {
    handleFilter();
  }, [
    reversed,
    property,
    salesTax,
    businessTax,
    franchiseTax,
    payroll,
    annualReport,
    bookKeeping,
    taxReturnPreparation,
    tPPreturnPreparation,
    selectedState,
    selectedYear,
    selectedDateStart,
    selectedDateEnd
  ]);
  useEffect(() => {
    getClients();
  }, []);
  return (
    <>
      {loading && (
        <div
          className="loader-wrapper"
          style={{ textAlign: 'center', marginTop: '5px' }}
        >
          <BarLoader color={'#1976D2'} loading={loading} width={'97vw'} />
        </div>
      )}
      <Box mx={5}>
        <Typography
          sx={{
            textAlign: 'center',
            fontWeight: 'bold',
            color: '#1976D2',
            fontSize: '1.5em',
            my: 2
          }}
        >
          Clients Report
        </Typography>
        <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                flexWrap: 'wrap',
                mt: { xs: 0, md: 0 }
              }}
            >
              <Box
                sx={{
                  width: '100%'
                }}
              >
                <Box
                  sx={{
                    display: 'inline-block',
                    textAlign: 'left',
                    marginRight: {
                      xs: '10px',
                      sm: '25px',
                      md: '50px'
                    },
                    width: 'fit-content',
                    cursor: 'pointer',
                    userSelect: 'none',
                    border: '2px solid grey',
                    borderRadius: '5px',
                    paddingRight: '10px'
                  }}
                  onClick={() => setReversed(!reversed)}
                >
                <Switch
                  checked={reversed}
                  onChange={() => setReversed(!reversed)}
                  sx={{
                    '& .MuiSwitch-switchBase.Mui-checked': {
                      color: '#1976D2'
                    },
                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                      backgroundColor: '#1976D2'
                    }
                  }}
                />
                Reverse
                </Box>
                <CheckItem
                  label="Property"
                  value={property}
                  setValue={setProperty}
                />
                <CheckItem
                  label="Sales Tax"
                  value={salesTax}
                  setValue={setSalesTax}
                />
                <CheckItem
                  label="Business tax"
                  value={businessTax}
                  setValue={setBusinessTax}
                />
                <CheckItem
                  label="Franchise Tax"
                  value={franchiseTax}
                  setValue={setFranchiseTax}
                />
                <CheckItem
                  label="Payroll"
                  value={payroll}
                  setValue={setPayroll}
                />
                <CheckItem
                  label="Annual report"
                  value={annualReport}
                  setValue={setAnnualReport}
                />
                <CheckItem
                  label="Book Keeping"
                  value={bookKeeping}
                  setValue={setBookKeeping}
                />
                <CheckItem
                  label="Tax Return preparation"
                  value={taxReturnPreparation}
                  setValue={setTaxReturnPreparation}
                />
                <CheckItem
                  label="TPP Return preparation"
                  value={tPPreturnPreparation}
                  setValue={setTPPReturnPreparation}
                />
              </Box>
            </Grid>
            <Grid item xs={6} md={2} sx={{ mt: { xs: 0, md: 0 } }}>
              <InputLabel htmlFor="state">State</InputLabel>
              <Autocomplete
                id="state"
                options={['All', ...states]}
                // getOptionLabel={(client) => client.legalBusinessName || ''}
                value={selectedState}
                onChange={(_, newValue) => setSelectedState(newValue)}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" size="small" />
                )}
              />
            </Grid>
            <Grid item xs={6} md={2} sx={{ mt: { xs: 0, md: 0 } }}>
              <InputLabel htmlFor="businessFormationDate">
                Year of Formation
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Box sx={{ width: '100%' }}>
                  <DatePicker
                    views={['year']}
                    openTo="year"
                    value={selectedYear}
                    onChange={handleYearChangeChange}
                    renderInput={(props) => (
                      <TextField {...props} style={{ width: '100%' }} />
                    )}
                    InputProps={{
                      size: 'small',
                      name: 'businessFormationDate'
                    }}
                  />
                </Box>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6} md={2} sx={{ mt: { xs: 0, md: 0 } }}>
              <InputLabel htmlFor="clientCreatedDateStart">Created Date Start</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Box sx={{ width: '100%' }}>
                  <DatePicker
                    views={['year', 'month', 'day']}
                    openTo="day"
                    value={selectedDateStart}
                    onChange={handleStartDateChange}
                    renderInput={(props) => (
                      <TextField {...props} style={{ width: '100%' }} />
                    )}
                    InputProps={{
                      size: 'small',
                      name: 'clientCreatedDateStart'
                    }}
                  />
                </Box>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6} md={2} sx={{ mt: { xs: 0, md: 0 } }}>
              <InputLabel htmlFor="clientCreatedDateEnd">Created Date End</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Box sx={{ width: '100%' }}>
                  <DatePicker
                    views={['year', 'month', 'day']}
                    openTo="day"
                    value={selectedDateEnd}
                    onChange={handleEndDateChange}
                    renderInput={(props) => (
                      <TextField {...props} style={{ width: '100%' }} />
                    )}
                    InputProps={{
                      size: 'small',
                      name: 'clientCreatedDateEnd'
                    }}
                  />
                </Box>
              </LocalizationProvider>
            </Grid>
            <Grid
              item
              xs={4}
              md={1.5}
              sx={{ mt: { xs: 0, md: 3 }, scale: { xs: 0.6 } }}
            >
              <Button variant="contained" color="warning" onClick={handleClear}>
                Clear
              </Button>
            </Grid>
            <Grid
              item
              xs={5}
              md={1}
              sx={{ mt: { xs: 0, md: 3 }, scale: { xs: 0.6 } }}
            >
              <Button
                variant="contained"
                color="success"
                onClick={handleExportToExcel}
              >
                Export
                <FileDownloadIcon />
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Box mt={3}>
          <Paper sx={{ width: '95vw', overflow: 'hidden' }} elevation={3}>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={clients.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <TableContainer sx={{ width: '100%', overflow: 'auto' }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align as any}
                        style={{
                          minWidth: column.minWidth,
                          fontWeight: '800',
                          color: '#1976D2',
                          backgroundColor: '#f5f5f5'
                        }}
                        sx={styles.cell}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clients &&
                    clients
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          <TableCell sx={styles.cell}>{index + 1}</TableCell>
                          <TableCell sx={styles.cell}>
                            {formatClientId(+row.id) || 'N/A'}
                          </TableCell>
                          <TableCell sx={styles.cell}>{row.state}</TableCell>
                          <TableCell sx={styles.cell}>
                            {row.legalBusinessName}
                          </TableCell>
                          <TableCell sx={styles.cell}>
                            {row.doingBusinessAs}
                          </TableCell>
                          <TableCell sx={styles.cell}>
                            {row.contactPerson}
                          </TableCell>
                          <TableCell sx={styles.cell}>
                            {row.contactEmail}
                          </TableCell>
                          <TableCell sx={styles.cell}>
                            {row.contactNumber}
                          </TableCell>
                          <TableCell sx={styles.cell}>
                            {row.accountantName}
                          </TableCell>
                          <TableCell sx={styles.cell} align="center">
                            <VisibilityIcon
                              onClick={() => handleView(row.id)}
                              style={{
                                color: '#1976D2',
                                cursor: 'pointer'
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </Box>
      <ViewClient
        open={isViewModalOpen}
        onClose={closeViewModal}
        client={client}
      />
    </>
  );
};

export default ClientReport;

const CheckItem = ({ value, setValue, label }) => {
  return (
    <Box
      sx={{
        display: 'inline-block',
        textAlign: 'left',
        marginRight: {
          xs: '10px',
          sm: '25px',
          md: '50px'
        },
        width: 'fit-content',
        cursor: 'pointer',
        userSelect: 'none'
      }}
      onClick={() => setValue(!value)}
    >
      <Checkbox
        checked={value}
        inputProps={{ 'aria-label': 'controlled' }}
        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
      />
      <span style={{ marginLeft: '-5px', marginTop: '15px' }}>{label}</span>
    </Box>
  );
};
